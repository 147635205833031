import {Fragment} from 'react';

import {clickedEnter} from '../helpers/clickedEnter.js';

import {useDispatch} from 'react-redux';

import {openSidebar} from '../actions/openSidebar';

import {useHistory} from 'react-router-dom';

const Nav = ({noSave, returnHome, useClass}) => {

    const history = useHistory();

    const dispatch = useDispatch();

    const saveProspectusHandler = (e) => {

        if ( ! clickedEnter(e) ) {
            return;
        }

        dispatch( openSidebar( 'login' ) );
    }

    const shareProspectusHandler = (e) => {

        if ( ! clickedEnter(e) ) {
            return;
        }

        dispatch( openSidebar( 'share' ) );
    }

    const talkToUsHandler = (e) => {

        if ( ! clickedEnter(e) ) {
            return;
        }

        dispatch( openSidebar( 'TalkToUs' ) );
    }

    const openMenuHandler = (e) => {

        if ( ! clickedEnter(e) ) {
            return;
        }

        dispatch( openSidebar( 'menu' ) );
    }

    const goTohome = (e) => {

        if ( ! clickedEnter(e) ) {
            return;
        }

        if ( undefined !== typeof returnHome ) {
            returnHome( true );
        }

        history.push('/');
    }

    return (
        <nav className={`nav ${useClass}`}>
            <ul>
                <li className="icon cursor" tabIndex="0" onKeyPress={goTohome} onClick={goTohome}>
                    <span className="hidden">Go to the homepage</span>
                    <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"></path></svg>
                </li>
                {
                    !noSave ? (
                        <Fragment>
                            <li className="icon cursor" tabIndex="0" onKeyPress={saveProspectusHandler} onClick={saveProspectusHandler}>
                                <span className="hidden">Save your prospectus</span>
                                <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z"></path></svg>
                            </li>
                            <li className="icon cursor" tabIndex="0" onKeyPress={shareProspectusHandler} onClick={shareProspectusHandler}>
                                <span className="hidden">Share your prospectus</span>
                                <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M352 320c-22.608 0-43.387 7.819-59.79 20.895l-102.486-64.054a96.551 96.551 0 0 0 0-41.683l102.486-64.054C308.613 184.181 329.392 192 352 192c53.019 0 96-42.981 96-96S405.019 0 352 0s-96 42.981-96 96c0 7.158.79 14.13 2.276 20.841L155.79 180.895C139.387 167.819 118.608 160 96 160c-53.019 0-96 42.981-96 96s42.981 96 96 96c22.608 0 43.387-7.819 59.79-20.895l102.486 64.054A96.301 96.301 0 0 0 256 416c0 53.019 42.981 96 96 96s96-42.981 96-96-42.981-96-96-96z"></path></svg>
                            </li>
                        </Fragment>
                    ) : ''
                }
                <li className="icon cursor" tabIndex="0" onClick={talkToUsHandler} onKeyPress={talkToUsHandler}>
                    <span className="hidden">Talk to us</span>
                    <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M416 192c0-88.4-93.1-160-208-160S0 103.6 0 192c0 34.3 14.1 65.9 38 92-13.4 30.2-35.5 54.2-35.8 54.5-2.2 2.3-2.8 5.7-1.5 8.7S4.8 352 8 352c36.6 0 66.9-12.3 88.7-25 32.2 15.7 70.3 25 111.3 25 114.9 0 208-71.6 208-160zm122 220c23.9-26 38-57.7 38-92 0-66.9-53.5-124.2-129.3-148.1.9 6.6 1.3 13.3 1.3 20.1 0 105.9-107.7 192-240 192-10.8 0-21.3-.8-31.7-1.9C207.8 439.6 281.8 480 368 480c41 0 79.1-9.2 111.3-25 21.8 12.7 52.1 25 88.7 25 3.2 0 6.1-1.9 7.3-4.8 1.3-2.9.7-6.3-1.5-8.7-.3-.3-22.4-24.2-35.8-54.5z"></path></svg>
                </li>
                <li className="icon cursor" tabIndex="0" onClick={openMenuHandler} onKeyPress={openMenuHandler}>
                    <span className="hidden">Open menu</span>
                    <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>
                </li>
            </ul>
        </nav>
    )
}

export default Nav;