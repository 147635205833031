import ReactDOM from 'react-dom';
import App from './App';

// GTM.
import TagManager from 'react-gtm-module'

// Redux Setup
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import { Provider } from 'react-redux';

// Import for async actions.
import thunk from 'redux-thunk';

import { BrowserRouter } from "react-router-dom";

// Allows us to use thunk && devtools extension.
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunk))
);


const tagManagerArgs = {
    gtmId: 'GTM-NDRPZV9',
    // gtmId: 'GTM-KF7KJJF',
    dataLayerName: 'PageDataLayer'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);

/**
 * Warning before leaving the page, will use custom text on older browsers.
 */
window.onbeforeunload = function() {
  return "Warning, any unsaved data will be lost!";
};