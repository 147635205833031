import {cardUrl} from '../api';
import {fetchCards} from '../helpers';

const getCards = (type) => async (dispatch) => {

    const data = await fetchCards( type, cardUrl() );

    dispatch({
        type: 'SET_CARDS',
        payload: {
            type: type,
            data: data.results.edges
        }
    })
}

export default getCards;