import {useDispatch} from 'react-redux';
import {decode} from 'html-entities';

const Course = ({ name, image, uid, description, items, type, index, bucket, inActive, canEdit, sectionTitle }) => {

    const dispatch = useDispatch();

    const handleClick = (e) => {

        if ( e ) {
            if ( e.charCode && e.charCode !== 13 ) {
                return;
            }
        }

        if ( ! e.target.classList.contains('remove') ) {

            const activeItem = {
                sectionTitle: sectionTitle,
                bucket: bucket,
                api_fields : {
                    courseDescription : description,
                },
                mediaFeaturedImageUrl: image,
                databaseId: uid,
                title: name,
                type: type,
                index : index,
                inActive: inActive,
            };

            dispatch({
                type : 'SELECT_DETAILS',
                payload : {
                    active: {
                        node : activeItem
                    },
                    items: items,
                },
            })
        } else {

            const currentItem = items[index];

            // We want to remove the card from view
            items.splice(index, 1)

            const action = inActive ? 'RESTORE_CARD' : 'TRASH_CARD';

            dispatch({
                type : action,
                payload : {
                    updatedItems: items,
                    currentItem: currentItem,
                    bucket: bucket
                },
            })
        }
    }

    const style = {
        backgroundImage: `url(${image})`
    };

    return (
        <article
            onClick={handleClick}
            onKeyPress={handleClick}
            tabIndex="0"
            id={uid} className={`card course ${inActive? ' inactive' : ''}`}
            style={style}>
            { canEdit && <span className="remove cursor"></span> }
            <h4 className="is-7">{decode(name)}</h4>
        </article>
    )
}

export default Course;