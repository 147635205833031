const initState = {
    user : {
        id: '',
        email : '',
        name: '',
        jwtAuthToken: false,
        userProspectus: false,
    },
    isLogged: false,
    userJustLoggedIn: false,
    logInErrors : false,
    registerErrors: false,
    forgottenError: false,
    forgottenSuccess: false,
    shareLink: false,
    resetSuccessful: false,
    resetPasswordError: false,
};

const userReducer = ( state = initState, action ) => {

    switch (action.type) {
        case 'RESET_ERRORS':
            return{
                ...state,
                logInErrors: false,
                registerErrors: false,
                forgottenError: false,
                forgottenSuccess: false,
                resetSuccessful: false,
                resetPasswordError: false,
            }
        case 'USER_REGISTER':
            return{
                ...state,
                userJustLoggedIn : true,
                isLogged : true,
                user: action.payload
            }
        case 'HIDE_SAVED':
            return{
                ...state,
                saved: false
            }
        case 'SAVED_PROSPECTUS':

            return{
                ...state,
                shareLink: action.payload.prospectus_code,
                saved: Date.now()
            }
        case 'USER_LOGGED_IN':
            return {
                ...state,
                userJustLoggedIn : true,
                isLogged : true,
                user : action.payload,
            };
        case 'FAILED_USER_LOG_IN':
            return {
                ...state,
                logInErrors : action.payload
            }
        case 'FAILED_REGISTER':
            return {
                ...state,
                registerErrors : action.payload
            }
        case 'FORGOTTEN_PASSWORD':
            return {
                ...state,
                forgottenError : false,
                forgottenSuccess: action.payload
            }
        case 'FAILED_FORGOTTEN_PASSWORD':
            return {
                ...state,
                forgottenSuccess: false,
                forgottenError: action.payload
            }
        case 'FAILED_RESET_PASSWORD':
            return{
                ...state,
                resetSuccessful: false,
                resetPasswordError: 'Oops, something went wrong',
            }
        case 'RESET_PASSWORD':
            return{
                ...state,
                resetSuccessful: true,
                resetPasswordError: false,
            }
        default:
            return {...state};
    }
}

export default userReducer;
