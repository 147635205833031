const Card = ({item, isSelected, title, image, description}) => {


    return(
        <div className={`single slide swiper-slide-active ${isSelected ? 'active' : ''}`}>
            <figure>
                { image && <img
                src={image}
                alt={title}
                title={title}
                /> }
                <figcaption className="is-family-secondary">{title}</figcaption>
            </figure>

            <div className="content">
                <div className="scroll">
                    <h2 className="is-3 has-underline">{title}</h2>
                    <div dangerouslySetInnerHTML={{__html: description}}></div>
                </div>
            </div>
        </div>
    )
}

export default Card;