import React, { useState } from "react";
import { useDispatch } from "react-redux";

import searchTermByCourse from '../../actions/searchTermByCourse';

import searchIcon from "../../img/icons/search.svg";
import arrow from '../../img/icons/arrow-left.svg';

const SelectorHeader = React.memo(({phase, clickedBack, numOfPhases}) => {
  const [term, setTerm] = useState("");

  const dispatch = useDispatch();

  const submitCourseSearch = (e) => {
    e.preventDefault();

    if ( '' === term ) {
      alert( 'Please type a search term' );
      return;
    }

    // Fire a request to loadCourses.
    dispatch( searchTermByCourse(term) );

    dispatch({ type: 'STARTED_SEARCH' })
  };

  const handleSearchChange = (e) => {

    // If empty run set courses that we have already loaded.
    if ( null === e.target.value || '' === e.target.value ) {
        setTerm(e.target.value);
        dispatch({ type : 'EMPTY_TERM_SEARCH' })
    } else {
        setTerm(e.target.value);
    }
}

  return (
    <header className="heading heading-selector">
      <div className="container">
      {
        1 === phase && 2 === numOfPhases ? (
          <React.Fragment>
            <div className="is-flex head">
              <h2 className="is-3">What do you want to study?</h2>
              <button className="button return" onClick={clickedBack}>
                <img src={arrow} alt="Back arrow icon" />
                <span>Back</span>
              </button>
            </div>
            <p>
              Search for a course or select subject areas from the bubbles below. Choose as many as you like.
            </p>

            <form onSubmit={submitCourseSearch} autoComplete="off" className="search">
              <fieldset>
                <input
                  required
                  type="search"
                  value={term}
                  placeholder="Search..."
                  onChange={handleSearchChange}
                />

                <button type="submit" className="search-icon">
                  <img
                    src={searchIcon}
                    alt="Search icon"
                    title="Submit search term"
                  />
                </button>
              </fieldset>
            </form>
          </React.Fragment>
        ) : (
          <div className="is-flex head">
            <HeaderText numOfPhases={numOfPhases} />
            <button className="button return" onClick={clickedBack}>
              <img src={arrow} alt="Back arrow icon" />
              <span>Back</span>
            </button>
          </div>
        )
      }
      </div>
    </header>
  );
})

const HeaderText = ({numOfPhases}) => {

  if ( 2 === numOfPhases ) {
    return <h2 className="is-3 no-mt">Select your courses</h2>;
  } else {
    return(
      <div>
        <h2 className="is-3 no-mt">Where will you live?</h2>
        <p>What type of accommodation would you like to live in?
          We&apos;ll populate your prospectus with choices based on your preference.</p>
      </div>
    )
  }
}

export default SelectorHeader;
