const initState = {
    sidebar : false,
    state : false,
    previous_state: false,
};

const sidebarReducer = ( state = initState, action ) => {

    switch (action.type) {
        case 'NO_PREVIOUS':
            return {
                ...state,
                previous_state : false,
            }
        case 'OPEN_SIDEBAR':

            document.body.style.overflow = 'hidden';

            return {
                ...state,
                sidebar: action.payload.mode,
                state: true,
                previous_state : state.state ? state.sidebar : false
            };
        case 'BACK_SIDEBAR':

            return {
                ...state,
                sidebar: action.payload.mode,
                state: true,
                previous_state : false
            };
        case 'CLOSE_SIDEBAR':

            document.body.style.overflow = 'initial';

            return{
                ...state,
                state: false,
                previous_state : false,
            }
        case 'UPDATE_SIDEBAR_MODE':

            return{
                ...state,
                sidebar: action.payload.mode,
                previous_state : state.state ? state.sidebar : false
            }
        default:
            return{
                ...state
            }
    }
}

export default sidebarReducer;
