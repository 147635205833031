import {useEffect, useState}from 'react';
import SelectorHeader from "../components/Selector/SelectorHeader";
import BubbleScroll from "../components/Selector/BubbleScroll";

import {useDispatch, useSelector} from 'react-redux';

import { useHistory } from "react-router-dom";

import {motion} from 'framer-motion';

import {pageAnimation} from  '../animations';

import getAccommodation from '../actions/getAccommodation';
import getSubjectAreas from '../actions/getSubjectAreas';

import LogoHeader from '../components/LogoHeader';

const Selector = ({numOfPhases, closeSelector}) => {

  const [items, setItems] = useState(false);
  const [activeType, setActiveType] = useState('term');
  const [phase, setPhase] = useState(1);
  const [lastLoad, setLastLoad] = useState( false );

  const dispatch = useDispatch();

  let history = useHistory();

  let {
    updated,
    subjectAreas,
    isLoading,
    potentialCourses,
    accommodation,
    searchedTerms,
    isSearching,
    searchLoading
  } = useSelector((state) => state.prospectus)

  /**
   * When the cards have loaded from the api &
   * been set in the prospectus view,
   * return the user to the prospectus view.
   */
  useEffect(() =>{
    if (updated) {
      dispatch({ type : 'HAS_UPDATED_CARDS' });
      history.push('/prospectus/me');
    }

  }, [updated])

  /**
   * When this view is first loaded, set the loader.
   */
  useEffect(() =>{
    dispatch({ type : 'INIT_SELECTOR_LOADING' });
  }, [])

  /**
   * Onload get the phase one items.
   */
  useEffect(() => {

    if ( 2 === numOfPhases ) {
      dispatch( getSubjectAreas( subjectAreas ) )
    } else {
      dispatch( getAccommodation( accommodation  ) )
    }

  }, [setItems, dispatch, getSubjectAreas, getAccommodation]);

  /**
   * When the subject or accomm state is updated, fire these state sets.
   */
  useEffect(() => {
    const switchDisplay = () => {
      if ( 1 === phase ) {
        if ( 2 === numOfPhases ) {
          if ( isSearching ) {
            setActiveType('term');
            // setPhase(2);
            setItems( searchedTerms );
          } else {
            setActiveType('term');
            setItems( subjectAreas );
          }
        } else {
          setActiveType('post');
          setItems( accommodation );
        }
      } else if ( 2 === phase ) {
        setActiveType('post');
        setItems( potentialCourses );
      }
    }

    switchDisplay()

  }, [subjectAreas, accommodation, potentialCourses, searchedTerms, setItems, isSearching, numOfPhases, phase]);

  /**
   * On load we need to always set the active loop,
   * so that we don't get caught between states.
   */
  useEffect(() => {
    if ( 2 === numOfPhases ) {
      setItems( subjectAreas );
    } else {
      setItems( accommodation );
    }
  }, [])

  /**
   * The loading screen text.
   */
  const displayContent = () => {

    if ( 1 === phase ) {
      if ( 1 === numOfPhases ) {
        // If accommm, if setting we're going back to the prospectus.
        if ( setItems === accommodation ) {
          return <h2 className="is-3">Adding selected accommodation to your prospectus....</h2>;
        } else {
          return <h2 className="is-3">Loading accommodation...</h2>;
        }

      } else {
        return <h2 className="is-3">Loading subject areas...</h2>;
      }
    } else {

      // First click
      if ( lastLoad ) {
        return <h2 className="is-3">We're just adding your courses to your prospectus...</h2>
      } else {
        return <h2 className="is-3">Loading courses for your selected subject areas...</h2>
      }

    }
  }

  /**
   * Show the correct screens when
   * the user clicks back in the selector.
   */
  const handleBackClick = () => {
    if ( 2 === phase ) {
      setPhase( 1 );
      setActiveType('term');
      setItems( subjectAreas );
    } else {
      closeSelector();
    }
  }

  return (
    <motion.div
      key="coursesPage"
      variants={pageAnimation}
      initial="initial"
      animate="visible"
      exit="exit"
      className="courses selector">
      <LogoHeader />
      <div className="container">

          <div className={`loadingSelector ${!isLoading ? 'hide' : ''}`}>
            <h2>Hold tight!</h2>
            { displayContent() }
          </div>

          <div className={`selector ${isLoading ? 'hide' : ''}`}>
              <SelectorHeader phase={phase} numOfPhases={numOfPhases} clickedBack={handleBackClick} />

              { searchLoading && <div className="loader"><div></div><div></div><div></div><div></div></div> }

              <BubbleScroll
                setPhase={(e) => setPhase(e)}
                phase={phase}
                activeType={activeType}
                items={items}
                numOfPhases={numOfPhases}
                potentialCourses={potentialCourses}
                setLastLoad={(e) => setLastLoad(true)}
                hide={searchLoading}
              />
          </div>
      </div>
    </motion.div>
  );
}

export default Selector;