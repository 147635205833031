import {useState, useEffect, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {motion} from 'framer-motion';
import {homePageAnimation} from '../animations';

import logo from '../img/logo.webp';
import logoFallback from '../img/logo.png';

import prospectusText from '../img/my-prospectus.svg';

import {strengthIndicator, strengthColor} from '../helpers/passwordStrength';

import sendResetPassword from '../actions/sendResetPassword';

const ResetPassword = () => {

    const [password, setPassword] = useState('');
    const [secondPassword, setSecondPassword] = useState('');
    const [error, setError] = useState(false);
    const [strength, setStrength] = useState('');
    const [colour, setColour] = useState('');
    const [resetText, setResetText] = useState('Reset password');
    const [token, setToken] = useState(false);
    const [secret, setsecret] = useState(false);

    const {resetPasswordError, resetSuccessful} = useSelector((state) => state.user)

    const dispatch = useDispatch();

    const passwordDiff = ( pass, otherPass ) => {
        if ( '' !== pass && '' !== otherPass ) {
            if ( pass !== otherPass ) {
                setError( 'Passwords must match' );
            } else {
                setError( false );
            }
        } else {
            setError( false );
        }
    }

    const changePassword = (e) => {

        setPassword( e.target.value );

        const newStrength = strengthIndicator( e.target.value );

        setStrength( newStrength )
        setColour( strengthColor( newStrength ) );

        if ( newStrength < 4 ) {
            setError( 'Password is too weak' );
        } else {
            passwordDiff( e.target.value, secondPassword );
        }
    }

    const changeSecondPassword = (e) => {

        setSecondPassword( e.target.value );

        const newStrength = strengthIndicator( e.target.value );

        if ( newStrength < 4 ) {
            setError( 'Passwords is too weak' );
        } else {
            passwordDiff( e.target.value, password );
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setResetText( 'Loading...' );

        if ( ! token || ! secret ) {
            setError( 'Oops, something went wrong!' );
        }
        else if ( ! error && strength >= 4 ) {
            dispatch({type: 'RESET_ERRORS'})
            dispatch(sendResetPassword(password, token, secret));
        }
    }

    useEffect(() => {
        setResetText( 'Reset password' );
    }, [error, resetPasswordError])

    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('key');
        const secret = urlParams.get('secret');

        if ( token ) {
            setToken( token );
        }
        if ( secret ) {
            setsecret( secret );
        }
    }, [])

    return(
        <motion.div
        layoutId="location"
        key="ResetPassword"
        variants={homePageAnimation}
        initial="initial"
        animate="visible"
        exit="exit"
        className="">

            <div className="home">
                <div className="container">
                    <a className="inline-block" href="https://glos.ac.uk">
                        <picture>
                            <source type="image/webp" srcSet={logo} />
                            <source srcSet={logoFallback} />
                            <img className="logo" style={{height: '62px'}} src={logo} alt="University of Gloucestershire logo" title="Visit our website" />
                        </picture>
                    </a>
                    <img src={prospectusText} style={{height: '84px'}} alt="My Glos prospectus" />

                    {
                        ! resetSuccessful ? (
                            <Fragment>
                                <strong>Please enter your new password</strong>

                                <form className="revert" onSubmit={handleSubmit}>

                                    <div className="errors">
                                        {error ?
                                            <div className="alert error">{error}</div>
                                        : ''}
                                        {resetPasswordError ?
                                            <div className="alert error">{resetPasswordError}</div>
                                        : ''}
                                    </div>

                                    <input
                                        required
                                        type="password"
                                        value={password}
                                        placeholder="New password..."
                                        onChange={changePassword}
                                    />

                                    <input
                                        required
                                        type="password"
                                        value={secondPassword}
                                        placeholder="Confirm new password..."
                                        onChange={changeSecondPassword}
                                    />

                                    <div className={`strength ${colour}`}>
                                        <span></span>
                                    </div>

                                    <button type="submit" className="button">{resetText}</button>
                                </form>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <h1 className="is-4">Your password has been reset</h1>
                                <Link
                                    className="button cursor"
                                    to={{
                                    pathname: '/',
                                }}>Create your prospectus</Link>
                            </Fragment>
                        )
                    }
                </div>
            </div>

        </motion.div>
    )
}

export default ResetPassword;