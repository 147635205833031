export const getItemsToAppend = ( newItems, oldItems ) => {

    if ( oldItems.length < 1 ) {
        return newItems;
    }

    let itemsToAppend = [],
        x = 0;

    while (x < newItems.length) {
        const newItem = newItems[x];

        if ( -1 === oldItems.findIndex(oldItem => oldItem.node.slug === newItem.node.slug) ) {
            // doesn't exist.
            itemsToAppend.push(newItem);
        }

        ++x;
    }

    return itemsToAppend;
}

/**
 * Loops the new items, checks if they're in the old items bucket.
 * If they are remove them, and return the old items bucket.
 */
export const removeItemsFromBin = (newItems, oldItems) => {

    if ( oldItems.length < 1 ) {
        return oldItems;
    }

    let x = 0;

    while (x < newItems.length) {
        const newItem = newItems[x];

        const index = oldItems.findIndex(oldItem => oldItem.node.slug === newItem.node.slug);
        if ( index > -1 ) {
            // Item exists.
            oldItems.splice( index, 1 );
        }

        ++x;
    }

    return oldItems;
}

/**
 * Get search terms
 */
export const getTermsBySlug = ( slugs, arrayToSearch ) => {

    const searchedTerms = [];

    let x = 0;

    while (x < slugs.length) {
        const term = slugs[x];

        const found = arrayToSearch.find(element => element.node.slug === term);
        searchedTerms.push( found );

        ++x;
    }

    return searchedTerms;
}

/**
 * Return the buckets that need to be updated.
 */
export const getStatesToUpdate = (bucket) => {

    const matchBucketToStore = {
        'course' : {
            'active' : 'activeCourses',
            'bin'    : 'inActiveCourses'
        },
        'campus' : {
            'active' : 'activeCampuses',
            'bin'    : 'inActiveCampuses'
        },
        'accommodation' : {
            'active' : 'activeAccommodation',
            'bin'    : 'inActiveAccommodation'
        },
        'career' : {
            'active' : 'activeCareer',
            'bin'    : 'inActiveCareer'
        },
        'protecting' : {
            'active' : 'activeProtect',
            'bin'    : 'inActiveProtect'
        },
        'safety' : {
            'active' : 'activeSafety',
            'bin'    : 'inActiveSafety'
        },
        'recommended' : {
            'active' : 'activeRecommended',
            'bin'    : 'inActiveRecommended'
        },
        'fitting_in' : {
            'active' : 'activeFitting',
            'bin'    : 'inActiveFitting'
        },
        'clubs' : {
            'active' : 'activeClubs',
            'bin'    : 'inActiveClubs'
        },
        'support' : {
            'active' : 'activeSupport',
            'bin'    : 'inActiveSupport'
        },
        'discover_glos' : {
            'active' : 'activeDiscover',
            'bin'    : 'inActiveDiscover'
        },
        'international_students' : {
            'active' : 'activeInternational',
            'bin'    : 'inActiveInternational'
        },
        'fun_facts' : {
            'active' : 'activeFacts',
            'bin'    : 'inActiveFacts'
        },
    }

    return matchBucketToStore[bucket];
}

/**
 * Helper fetch function to fetch cards.
 */
export const fetchCards = async ( type, cardUrl ) => {

    const response = await fetch( cardUrl + '?section=' + type, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    })

    return await response.json();
}

/**
 * Takes in data & orders it A-Z
 */
export const alphabetizeData = ( data ) => {

    return data.sort((a, b) => a.node.title.localeCompare(b.node.title))
    .reduce((r, e) => {
        const key = e.node.title[0];
        if(!r[key]) r[key] = []
        r[key].push(e);
        return r;
    }, {});
}

/**
 * verify recaptcha
 */
export const verifyRecaptcha = async ( url, action ) => {

    const token = await window.grecaptcha.execute('6Ldes10aAAAAAH1XvUCOncf4v5udtHa282dtRCRU', { action: action }).then(token => {
    });

    const data = await fetch( 'https://api.ipify.org/?format=json' )
        .then( data => data.json())

    const result = await fetch( url + 'verify', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "g-recaptcha-response": token,
                "remote_ip": data.ip
            })
        }).then(res => res.json()).then(res => {
            // res is the response object.
            return res.success
        });

    return result;
}

export const onMouseMove = ( e ) => {
    const screenCenter = {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2
    }

    const moveX = e.x - screenCenter.x;
    const moveY = e.y - screenCenter.y;
    let root = document.documentElement;

    root.style.setProperty('--x', moveX / 10 + '%');
    root.style.setProperty('--y', moveY / 10 + '%');
}

export const animateChildren = ( origin ) => {
    const parent = document.getElementById('bubble__scroll');

    if ( parent ) {

        let x = 0,
            y = 0,
            child;

        const minScale = .3;
        const maxScale = 1.1;
        const scaleRange = maxScale - minScale;

        const children = Array.from(parent.children);
        const length = children.length;

        while (x < length) {
            child = children[x];

            const r = child.getBoundingClientRect();
            const childX = r.right - (r.width / 2);
            const childY = r.bottom - (r.height / 2);
            const distanceY = Math.max(origin.y, childY) - Math.min(origin.y, childY);
            const distanceX = Math.max(origin.x, childX) - Math.min(origin.x, childX);
            const hypot = Math.hypot(distanceX, distanceY);

            child.distance = Math.round(hypot);

            ++x;
        }

        children.sort( dynamicSort( 'distance' ) ).reverse();

        while (y < length) {
            child = children[y];

            child.style.setProperty('--scale', minScale + ((y / length) * scaleRange));

            ++y;
        }
    }
}

const dynamicSort = ( property ) => {

    let sortOrder = 1;

    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a, b) {
        let result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
    }
}

export const getAlphabetStates = ( e ) => {

    const states = {
        'courses' : {
            'alphabet' : 'coursesAlphabetical',
            'cpt'      : 'courses',
            'search'   : 'searchedCourses',
        },
        'accommodation' : {
            'alphabet' : 'accommodationAlphabetical',
            'cpt'      : 'accommodation',
            'search'   : 'searchedAccommodation',
        },
        'discover_glos' : {
            'alphabet' : 'discoverAlphabetical',
            'cpt'      : 'discover',
            'search'   : 'searchedDiscover',
        },
        'career' : {
            'alphabet' : 'careerAlphabetical',
            'cpt'      : 'career',
            'search'   : 'searchedCareer',
        },
        'protecting' : {
            'alphabet' : 'protectingAlphabetical',
            'cpt'      : 'protecting',
            'search'   : 'searchedProtecting',
        },
        'safety' : {
            'alphabet' : 'safetyAlphabetical',
            'cpt'      : 'safety',
            'search'   : 'searchedSafety',
        },
        'fitting_in' : {
            'alphabet' : 'fittingInAlphabetical',
            'cpt'      : 'fitting_in',
            'search'   : 'searchedFittingIn',
        },
        'clubs' : {
            'alphabet' : 'clubsAlphabetical',
            'cpt'      : 'clubs',
            'search'   : 'searchedClubs',
        },
        'support' : {
            'alphabet' : 'supportAlphabetical',
            'cpt'      : 'support',
            'search'   : 'searchedSupport',
        },
        'fun_facts' : {
            'alphabet' : 'funFactsAlphabetical',
            'cpt'      : 'fun_facts',
            'search'   : 'searchedFunFacts',
        },
        'international_students' : {
            'alphabet' : 'internationalAlphabetical',
            'cpt'      : 'international_students',
            'search'   : 'searchedInternational',
        },
    };

    return states[e];
}

