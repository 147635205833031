import './scss/style.scss';

// Allows us to lazy load as a  different file.
import {lazy, Suspense, useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";

import supportsWebp from './helpers/supportsWebp';

import Home from './pages/home';
import Courses from './pages/courses';
import Prospectus from './pages/prospectus';
import FourOhFour from './pages/fourOhFour';
import ResetPassword from './pages/ResetPassword';

import { AnimatePresence } from 'framer-motion';

const Sidebar = lazy(() => import( './components/Sidebar' ));

function App() {

  useEffect(() => {

    if ( supportsWebp() ) {
      document.body.classList.add( 'hasWebp' )
    } else {
      document.body.classList.add( 'noWebp' )
    }
  }, [])

  return (
    <Router>

      <Suspense className="sidebar" fallback={'loading...'}>
        <Sidebar />
      </Suspense>

      <Route
        render={({ location }) => (
          <AnimatePresence>
            <Switch location={location} key={location.pathname}>
              <Route exact path="/" component={Home} layoutId="location" key="homePage" />
              <Route exact path={['/prospectus/:id']} component={Prospectus} layoutId="location" key="prospectusPage" />
              <Route exact path="/everything" component={Courses} layoutId="location" key="coursesPage" />
              <Route exact path="/reset-password" component={ResetPassword} layoutId="location" key="ResetPassword" />
              <Route path='*' exact={true} component={FourOhFour} />
            </Switch>
          </AnimatePresence>
        )}
      />
    </Router>
  );
}

export default App;
