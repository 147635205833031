import {apiUrl} from '../api';
const fetchCoursesByTerm = (selectedItems) => async (dispatch) => {
    // Foreach here, so that we can output an array like structure in template literals
    let arrayToSend = '';
    let index = 0;

    while (index < selectedItems.length) {
        const item = selectedItems[index];

        arrayToSend += '"' + item.toString() + '"';
        if ( index !== selectedItems.length - 1 ) {
            arrayToSend += ',';
        }

        ++index;
    }

    // arrayToSend = '"performing-arts-production"';
    const response = await fetch( apiUrl(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            { query: `
                query {
                    courses(
                        first: 300,
                        where: {
                            taxQuery: {
                                relation: AND
                                taxArray: [
                                    {
                                        terms: [${arrayToSend}],
                                        taxonomy: SUBJECT_AREA,
                                        operator: IN,
                                        field: SLUG
                                    },
                                    {
                                        terms: ["ugt"],
                                        taxonomy: STUDY_LEVEL,
                                        operator: IN,
                                        field: SLUG
                                    }
                                ]
                            }
                        }
                    ) {
                        edges {
                            node {
                                title
                                slug
                                id
                                uri
                                link
                                mediaFeaturedImageUrl
                                api_fields {
                                    courseDescription
                                }
                                campuses {
                                    edges {
                                        node {
                                            id
                                            slug
                                        }
                                    }
                                }
                                subjectAreas {
                                    edges {
                                        node {
                                            id
                                            slug
                                        }
                                    }
                                }
                                featuredImage {
                                    node {
                                        mediaDetails {
                                            sizes {
                                                sourceUrl
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `}
        ),
    })
    const data = await response.json();

    dispatch({
        type: 'SET_SUBJECT_COURSES',
        payload: {
            courses : data.data.courses.edges,
        }
    })
}
export default fetchCoursesByTerm;