
import {memo, useEffect, Fragment} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Course from '../Card/Course';

import {loadCourses} from '../../actions/loadCourses';

import backupImg from '../../img/course-img.webp';

const CourseList = memo(() => {

    // Store.
    let {
        coursesAlphabetical,
        discoverAlphabetical,
        accommodationAlphabetical,
        careerAlphabetical,
        protectingAlphabetical,
        safetyAlphabetical,
        fittingInAlphabetical,
        clubsAlphabetical,
        supportAlphabetical,
        funFactsAlphabetical,
        internationalAlphabetical,
        isLoading,
        isSearch,
        activeSearch,
        searchType,
        previous,
        scrollLoading
    } = useSelector((state) => state.course)

    const dispatch = useDispatch();

    let activeLoop = coursesAlphabetical;

    switch( searchType ) {
        case 'accommodation':
            activeLoop = accommodationAlphabetical;
            break;
        case 'discover_glos':
            activeLoop = discoverAlphabetical;
            break;
        case 'career':
            activeLoop = careerAlphabetical;
            break;
        case 'protecting':
            activeLoop = protectingAlphabetical;
            break;
        case 'safety':
            activeLoop = safetyAlphabetical;
            break;
        case 'fitting_in':
            activeLoop = fittingInAlphabetical;
            break;
        case 'clubs':
            activeLoop = clubsAlphabetical;
            break;
        case 'support':
            activeLoop = supportAlphabetical;
            break;
        case 'fun_facts':
            activeLoop = funFactsAlphabetical;
            break;
        case 'international_students':
            activeLoop = internationalAlphabetical;
            break;
        default:
            activeLoop = coursesAlphabetical;
            break;
    }

    // On first mount load courses.
    useEffect(() => {

        if ( coursesAlphabetical.length < 1 ) {
            dispatch( loadCourses('' , '') );
        }
    }, []);

    const loadMoreCourses = () => {

        if ( 'courses' === searchType && ! isLoading && ! scrollLoading ) {
            dispatch( { type: 'SCROLL_LOADING', payload: true } )

            if ( '' !== previous ) {
                dispatch( loadCourses('' , 'courses', '', previous) );
            }
        }
    }

    /**
     * If we are not loading the main data,
     * the type is courses && we're not already loading more.
     * Then render the load more btn.
     */
    const renderLoadMoreBtn = () => {

        if ( 'courses' !== searchType || '' === previous ) {
            return '';
        }

        if ( ! isLoading ) {
            if ( scrollLoading ) {
                return <div className="loader"><div></div><div></div><div></div><div></div></div>
            } else {
                return <button onClick={loadMoreCourses} className="login button loadBtn">Load More</button>
            }
        }
    }

    return(
        <div className="courseList">

                { isLoading ? (
                    <div
                    key="loadingCourses"
                    // variants={authAnimation}
                    initial="initial"
                    animate="visible"
                    exit="exit"
                    layoutId="courses"
                    >
                        <div className="loader"><div></div><div></div><div></div><div></div></div>
                    </div>
                ) :
                    <div
                    key="courseList"
                    // variants={authAnimation}
                    initial="initial"
                    animate="visible"
                    exit="exit"
                    layoutId="courses"
                    >
                        {
                            !isSearch ?
                                activeLoop && Object.keys(activeLoop).length > 0 ? (
                                    <Fragment>
                                        {Object.entries(activeLoop).map(([letter, itemList], i) => {
                                            return (
                                                <div key={i} className="letter">
                                                    <h3>{letter}</h3>

                                                    <div className="cardList">
                                                        {
                                                            itemList.map(
                                                                (item, key) => {

                                                                    return (
                                                                        <Course
                                                                            type="post"
                                                                            canEdit={false}
                                                                            items={itemList}
                                                                            key={key}
                                                                            uid={item.node.databaseId}
                                                                            description={item.node.api_fields.courseDescription}
                                                                            name={item.node.title}
                                                                            image={item.node.mediaFeaturedImageUrl ? item.node.mediaFeaturedImageUrl : backupImg}
                                                                        />
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {renderLoadMoreBtn()}
                                    </Fragment>
                                    ) : (
                                        <div><h2>Oops, we couldn't find any results!</h2></div>
                                    )
                                : (
                                    <Fragment>
                                        {
                                            activeSearch && activeSearch.length > 0 ? (
                                                <div className="cardList searchResults">
                                                    {
                                                        activeSearch.map(
                                                            (result, key) => {
                                                                return (
                                                                    <Course
                                                                        type="post"
                                                                        canEdit={false}
                                                                        items={activeSearch}
                                                                        key={key}
                                                                        uid={result.node.databaseId}
                                                                        description={result.node.api_fields.courseDescription}
                                                                        name={result.node.title}
                                                                        image={result.node.mediaFeaturedImageUrl ? result.node.mediaFeaturedImageUrl : backupImg}
                                                                    />
                                                                )
                                                            }
                                                        )
                                                    }
                                                </div>
                                            ) : (
                                                <div className="cardList searchResults error">
                                                    <h2>Oops, we couldn't find any search results!</h2>
                                                </div>
                                            )
                                        }
                                    </Fragment>
                                )
                            }
                    </div>
                }
        </div>
    )
});

export default CourseList;