import Course from "../Card/Course";
import InsertCard from './InsertCard.js';
import Tooltip from '../Tooltips/Tooltip';

import CarouselTitle from './CarouselTitle';

import backupImg from '../../img/course-img.webp';

const DummyItems = () => {
    let content = [],
    i = 0;

    while (i < 4) {
        content.push(
            <article key={i} className="card course lazy-bg is-loading">
                <h4 className="is-7 title"></h4>
            </article>
        );
        ++i;
    }

    return content;
}

const CardCarousel = (
    {
        title,
        subtitle,
        search,
        text,
        items,
        canToggle,
        type,
        canEdit,
        bucket,
        inactive,
        loadItems,
        dummy,
        useClass
    }
    ) => {

    if ( !canEdit && 0 === items.length ) {
        return(
            ''
        );
    }

    return (
        <div id={bucket} className={`card-slider${canToggle ? ' can_toggle' : ''} ${useClass}`}>

            {
                title && <CarouselTitle search={search} loadItems={loadItems} title={title} isMain={true} canToggle={canToggle} hasItems={items.length > 0 && bucket !== 'fun_facts'} />
            }

            {
                subtitle && <CarouselTitle search={search} loadItems={loadItems} title={subtitle} isMain={false} canToggle={canToggle && !title} />
            }

            <div className="carousel_toggle">
                {text && <div className="text">{text}</div>}

                <div>
                    <div className="cardCarousel">
                        <div className="innerCarousel">

                            {
                                ! loadItems && dummy && <DummyItems />
                            }

                            {
                                items && (items.length > 0) ? (
                                    items.map(
                                        (item, key) => {

                                            return (
                                                <Course
                                                    sectionTitle={title}
                                                    className="card"
                                                    items={items}
                                                    index={key}
                                                    key={key}
                                                    bucket={bucket}
                                                    uid={item.node.slug ? item.node.slug : ''}
                                                    description={item.node.api_fields ? item.node.api_fields.courseDescription : ''}
                                                    name={item.node.title ? item.node.title : ''}
                                                    type={type}
                                                    canEdit={canEdit}
                                                    inActive={false}
                                                    image={item.node.mediaFeaturedImageUrl ? item.node.mediaFeaturedImageUrl : backupImg}
                                                />
                                            )
                                        }
                                    )
                                ) : ''
                            }

                            {
                                ( 'course' === bucket ||
                                'accommodation' === bucket ) &&
                                ( items.length > 0 ||
                                inactive.length > 0 ) && canEdit? (
                                    <InsertCard
                                        bucket={bucket}
                                    />
                                ) : ''
                            }

                            {
                                canEdit && inactive && inactive.length > 0 ? (
                                    inactive.map(
                                        (inactiveItem, index) => {
                                            return (
                                                <Course
                                                    key={index}
                                                    sectionTitle={title}
                                                    className="card"
                                                    items={inactive}
                                                    index={index}
                                                    bucket={bucket}
                                                    uid={inactiveItem.node.slug ? inactiveItem.node.slug : ''}
                                                    description={inactiveItem.node.api_fields.courseDescription ? inactiveItem.node.api_fields.courseDescription : ''}
                                                    name={inactiveItem.node.title ? inactiveItem.node.title : ''}
                                                    type={type}
                                                    canEdit={canEdit}
                                                    inActive={true}
                                                    image={inactiveItem.node.mediaFeaturedImageUrl ? inactiveItem.node.mediaFeaturedImageUrl : backupImg}
                                                />
                                            )
                                        }
                                    )
                                ) : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                (title === 'courses') ? (
                <Tooltip
                styleName="two"
                title="Swipe"
                text="View all your selected courses by swiping to the left"/>
                ) : ''
            }
        </div>
    );

}

export default CardCarousel;
