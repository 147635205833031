
import IconArrow from '../Icons/IconArrow';

const Tooltip = ({title, text, back, skip, next, finish, actionNext, actionPrevious, actionClose, styleName = '', finishText = 'Complete'}) => {

    return (
        <div className={ `tooltip ${styleName}` }>
            <div className="tooltip__inner">
                <div className="tooltip__top">
                    <div className="swipe">
                        <div className="arrow is-flex"> <IconArrow width="20" height="20" color="#ffffff" /></div>
                    </div>
                    <div className="tooltip__title text-center"><h2 className="is-4"> {title}</h2></div>
                    <div className="tooltip__instructions text-center">{text}</div>
                </div>
                <div className="tooltip__buttons is-flex space-between">
                    {back && <span onClick={actionPrevious} className="tooltip__btn tooltip__btn--back is-flex">Back</span>}
                    {skip && <div onClick={actionClose} className="tooltip__btn tooltip__btn--skip is-flex">Skip</div>}
                    {next && <span onClick={actionNext} className="tooltip__btn tooltip__btn--next is-flex">Next</span>}
                    {finish && <span onClick={actionClose} className="tooltip__btn tooltip__btn--next is-flex">{finishText}</span>}
                </div>
            </div>
        </div>
    )
}

export default Tooltip;