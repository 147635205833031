export const openSidebar = (mode) => async(dispatch) => {

    dispatch({
        type : 'OPEN_SIDEBAR',
        payload : {
            mode: mode,
        },
    })

}
