import { useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';

import Header from '../components/CourseListing/Header';
import CourseList from '../components/CourseListing/CourseList';
import Details from '../components/Details/Details';

import Nav from '../components/Nav';

import {motion} from 'framer-motion';

import {pageAnimation, authAnimation} from '../animations';

const Courses = props => {

    // selectedCourse from selected course.
    const {selectedDetail, isDetailSelected} = useSelector((state) => state.prospectus)

    const [transform, setTransform] = useState( '' );

    const dispatch = useDispatch();

    useEffect(() => {

        setTimeout(() => {
            setTransform( true );
        }, 1500);

        return () => {
            document.body.style.overflow = 'auto';
            dispatch({ type: 'CLOSE_DETAILS' });
        }
    }, []);

    useEffect(() => {
        if ( isDetailSelected ) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isDetailSelected])

    const returnHome = () => {
        setTransform( false );
    }

    return (
        <motion.div
        key="coursesPage"
        variants={undefined !== props.location.state && 'home' === props.location.state.previous ? pageAnimation : authAnimation}
        initial="initial"
        animate="visible"
        exit="exit"
        className={`courses ${transform ? 'transform' : ''}`}
        >

            <Nav noSave={true} returnHome={returnHome} />

            <div className={`container ${isDetailSelected ? 'detail':''}`}>
                    {
                        isDetailSelected &&
                        <motion.div
                        key="courseDetail"
                        variants={authAnimation}
                        initial="initial"
                        animate="visible"
                        exit="exit"
                        layoutId="courses"
                        className="float-detail"
                        >
                            <Details selected={selectedDetail} />
                        </motion.div>
                    }

                    <div className={`courseListWrapper ${isDetailSelected ? 'detail':''}`}>
                        <Header />
                        <CourseList  />
                    </div>
            </div>
        </motion.div>
    )
}

export default Courses;