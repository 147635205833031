import {useState} from  'react';
import {useDispatch} from 'react-redux';
import {decode} from 'html-entities';

import LogoHeader from '../../components/LogoHeader';
import externalIcon from '../../img/icons/external-link.svg';


import Card from './Card';

import backupImg from '../../img/course-img.webp';
import backArrow from '../../img/icons/arrow-left.svg';

// Swiper Js
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Controller, EffectFade, Keyboard } from 'swiper';

// Swiper Scss
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/effect-fade/effect-fade.scss';

SwiperCore.use([Navigation, Controller, EffectFade, Keyboard]);

const Details = ({items, selected}) => {

    const dispatch = useDispatch();

    const [controlledSwiper, setControlledSwiper] = useState(null);
    const [activeSlide, setActiveSlide] = useState(1);
    const [loopItems, setLoopItems] = useState(items);

    const changeActiveState = (e, index, remove) => {

        const currentItem = items[index];

        if ( remove && loopItems.length > 1 ) {
            // We want to remove the card from view
            items.splice(index, 1)

            setLoopItems( items );
        }

        dispatch({
            type : 'TRASH_CARD',
            payload : {
                currentItem: currentItem,
                bucket: selected.node.bucket,
                index: items[index] ? items[index].node.slug : '',
            },
        })
    }

    let image = selected.node.mediaFeaturedImageUrl ? selected.node.mediaFeaturedImageUrl : backupImg;

    return(
        <div className={`wrapper ${loopItems ? 'prospectus' : 'all'}`}>

            {
                'undefined' !== typeof items && <LogoHeader />
            }

            <div className={`is-flex space-between detail-head ${undefined === typeof items || 'fun_facts' === selected.node.bucket ? 'facts' : ''}`}>
                <div className="detailTitle">
                    {
                        selected.node.sectionTitle ? selected.node.sectionTitle : ""
                    }
                </div>
                <div className="is-flex detail-buttons">
                    {
                        items && items.length > 0 ? (
                            <span className="is-flex slide-count is-family-secondary">{activeSlide} / {items.length}</span>
                        ) : ''
                    }
                    <button className="cursor button grey return restrict" onClick={(e) => dispatch( {type: 'CLOSE_DETAILS' } )}>
                        <img src={backArrow} alt="Back arrow icon" />
                        <span>Back</span>
                    </button>
                </div>
            </div>

            {
                loopItems && Array.isArray(loopItems) ? (
                    <div className="detail-cards">

                        <div className="figures child">
                            <Swiper
                            navigation
                            Keyboard
                            slidesPerView={1}
                            className="image-slider"
                            controller={{ control: controlledSwiper }}
                            spaceBetween={0}
                            slideToClickedSlide
                            centeredSlides
                            updateOnWindowResize
                            autoHeight={true}
                            initialSlide={'undefined' !== typeof selected.node.index ? selected.node.index : 0}
                            breakpoints={{
                                1014: {
                                    slidesPerView: 3,
                                }
                            }}
                            onSwiper={(e) => setActiveSlide( e.activeIndex + 1 )}
                            onSlideChange={(e) => setActiveSlide( e.activeIndex + 1 )}
                            id="image-slider">
                                    {loopItems.map(
                                        (item, key) => {
                                            return (
                                                <SwiperSlide key={`image-${key}`} virtualIndex={`image-${key}`} className="detail slide">
                                                    {!selected.node.inActive && loopItems.length > 1 && <span className="remove cursor" onClick={(e) => changeActiveState( item, key, true )}></span>}
                                                    <figure>
                                                        <img
                                                        src={item.node.mediaFeaturedImageUrl ? item.node.mediaFeaturedImageUrl : backupImg}
                                                        alt={item.node.title}
                                                        title={item.node.title}
                                                        />
                                                    </figure>
                                                    <figcaption className="is-family-secondary">{decode(item.node.title)}</figcaption>
                                                </SwiperSlide>
                                            )
                                        }
                                    )}
                            </Swiper>
                        </div>

                        <div className="contents child">
                            <Swiper
                            id="content-slider"
                            effect="fade"
                            autoHeight
                            updateOnWindowResize
                            allowTouchMove={false}
                            initialSlide={'undefined' !== typeof selected.index ? selected.index : 0}
                            className="content-slider"
                            onSwiper={setControlledSwiper}>
                                    {loopItems.map(
                                        (item, index) => {
                                            return (
                                                <SwiperSlide className="slide" key={`content-${index}`} virtualIndex={`content-${index}`}>
                                                    <div className="content">
                                                        <div className="scroll">
                                                            <h2 className="is-3 has-underline">{decode(item.node.title)}</h2>
                                                            <div className="content-text" dangerouslySetInnerHTML={{__html: item.node.api_fields.courseDescription}}></div>
                                                            { item.node.link &&
                                                            <a className="button login return" href={item.node.link} target="_blank" rel="noreferrer noopener">
                                                                View full information
                                                                <img src={externalIcon} alt="External link icon" />
                                                            </a>}
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        }
                                    )}
                            </Swiper>
                        </div>
                    </div>
                ) : (
                    <div className="detail-cards no-loop">
                        <div className="child">
                            <Card
                                item={selected}
                                title={decode(selected.node.title)}
                                description={selected.node.api_fields.courseDescription}
                                image={image}
                            />
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Details;