import {apiUrl} from '../api';

const uniq = (a) => {
    return a.sort().filter(function(item, pos, ary) {
        return !pos || item !== ary[pos - 1];
    });
}

const searchTermByCourse = (searchTerm) => async (dispatch) => {

    let subject_areas = [];

    const response = await fetch( apiUrl(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            { query: `
                query {
                    courses(where: {
                        metaQuery: {
                            metaArray: {
                                key: "pageName",
                                value: "${searchTerm}",
                                compare: LIKE
                            }
                        },
                        taxQuery: {
                            taxArray: {
                                terms: ["ugt"],
                                taxonomy: STUDY_LEVEL,
                                operator: IN,
                                field: SLUG
                            }
                        }
                    }) {
                        nodes {
                            title
                            subjectAreas {
                                edges {
                                    node {
                                        slug
                                    }
                                }
                            }
                            subjectAreas {
                                edges {
                                    node {
                                        id
                                        slug
                                    }
                                }
                            }
                        }
                    }
                }
            `}
        ),
    })

    const data = await response.json();
    const courses = data.data.courses.nodes;

    if ( courses ) {
        courses.forEach(course => {

            course.subjectAreas.edges.forEach(subject => {
                subject_areas.push( subject.node.slug );
            });
        });
        subject_areas = uniq(subject_areas)
    }

    dispatch({
        type: 'SEARCH_TERM_BY_COURSE',
        payload: {
            results : subject_areas
        }
    })
}

export default searchTermByCourse;