

const setAccommodation = (selectedItems, potentialAcommodation) => async (dispatch) => {

    let activeAccommodation = [];
    let index = 0;

    while (index < potentialAcommodation.length) {
        const item = potentialAcommodation[index];

        if ( selectedItems.includes( item.node.slug ) ) {
            activeAccommodation.push( item );
        }

        ++index;
    }

    dispatch({ type: 'APPEND_ACCOMMODATION', payload: activeAccommodation});
}

export default setAccommodation;