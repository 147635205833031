import React, {memo, useEffect, useState} from 'react';
import logo from "../img/glos-logo-white.webp";
import logoFallback from "../img/glos-logo-white.png";
import { motion, AnimatePresence } from "framer-motion";
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import Tour from 'reactour';

import CardCarousel from '../components/Prospectus/CardCarousel';

import LoadingScreen from '../components/LoadingScreen';

import Selector from './selector'

import Nav from '../components/Nav';

import Details from '../components/Details/Details';

import { authAnimation, pageAnimation } from "../animations";

import initialDataLoad from '../actions/initialDataLoad';
import loadProspectus from '../actions/loadProspectus';

import steps from '../helpers/tourSteps';

const Prospectus = memo(() => {

  const accentColor = "#5cb7b7";

  const {user} = useSelector((state) => state.user)
  const [transform, setTransform] = useState( '' );

  const {
    selectedDetail,
    isDetailSelected,
    listOfDetails,
    canEdit,
    inActiveCourses,
    inActiveCampuses,
    inActiveAccommodation,
    inActiveRecommended,
    activeRecommended,
    activeCourses,
    activeCampuses,
    activeAccommodation,
    selector,
    phases,
    updated,
    activeCareer,
    inActiveCareer,
    activeProtect,
    inActiveProtect,
    activeSafety,
    inActiveSafety,
    activeFitting,
    inActiveFitting,
    activeClubs,
    inActiveClubs,
    activeSupport,
    inActiveSupport,
    activeDiscover,
    inActiveDiscover,
    activeInternational,
    inActiveInternational,
    activeFacts,
    inActiveFacts,
    loadedProspectus,
    hasMainCookie
  } = useSelector((state) => state.prospectus)

  const dispatch = useDispatch();
  const location = useLocation();

  const [hideProspectus, setHideProspectus] = useState(false);

  useEffect(() => {
    const path = window.location.pathname.split("/").pop();

    // If we haven't loaded recommended yet.
    if ( !loadedProspectus && 'me' === path ) {
      dispatch( initialDataLoad(location) );
      dispatch( { type: 'INIT_PROSPECTUS' } )
    } else if ( 'me' !== path ) {
      dispatch( loadProspectus( path, user.jwtAuthToken ) )
    }
  }, []);

  useEffect(() => {

    if (selector) {
      setTimeout(() => {
        setHideProspectus(true)
      }, 600);
    } else {
      if (updated) {
        setHideProspectus(false);
        dispatch({ type : 'FINISHED_UPDATE' });
      }
    }

  }, [selector, setHideProspectus]);

  useEffect(() => {
    if ( document.getElementById('prospectus-main') ) {

      if ( true === isDetailSelected ) {
        document.getElementById('prospectus-main').scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      } else {
        console.log( selectedDetail )
        setTimeout(() => {
          document.getElementById('prospectus-main').scroll({
            top: document.getElementById(selectedDetail.node.bucket).offsetTop,
            left: 0,
            behavior: 'smooth'
          })
        }, 500);
      }
    }
  }, [isDetailSelected])

  useEffect(() => {

    setTimeout(() => {
        setTransform( true );
    }, 1500);
  }, []);

    const returnHome = () => {
        setTransform( false );
    }

  const closeSelector = () => {
    setHideProspectus(false)

    setTimeout(() => {
        dispatch({type: 'HAS_UPDATED_CARDS'})
    }, 600);
  }

    return (
      <motion.div
        key="coursesPage"
        variants={authAnimation}
        initial="initial"
        animate="visible"
        exit="exit">

        {
          ! hasMainCookie &&
          <Tour
            closeWithMask
            steps={steps}
            showCloseButton={false}
            showNumber={false}
            showButtons={false}
            showNavigation={false}
            isOpen={!hasMainCookie}
            maskClassName="mask"
            disableInteraction
            className="helper"
            rounded={5}
            accentColor={accentColor}
            onRequestClose={() => dispatch( {type : 'FINISH_TOOLTIPS_MAIN'} )}
          />
        }

          <AnimatePresence>

        {
          window.location.pathname.split("/").pop() !== 'me' && ! loadedProspectus ? (
            <motion.div
              key="loadingProspectus"
              layoutId="selector"
              variants={authAnimation}
              initial="initial"
              animate="visible"
              exit="exit">
                <div className="courses loading-screen">
                  <LoadingScreen content="Loading prospectus" isLoading={true} />
                </div>
              </motion.div>
          ) : ''
        }

        {
          true === selector ? (
            <motion.div
              key="prospectusSelector"
              layoutId="selector"
              variants={pageAnimation}
              initial="initial"
              animate="visible"
              exit="exit">
              <Selector numOfPhases={phases} closeSelector={closeSelector} layoutId="location" key="selectorPage" />
            </motion.div>
          ):''
        }

        {
          ! hideProspectus && loadedProspectus ? (
            <motion.div
              key="prospectus"
              layoutId="selector"
              variants={authAnimation}
              initial="initial"
              animate="visible"
              exit="exit"
              id="prospectus-main"
              className={`prospectus-main ${transform ? 'transform' : ''} ${isDetailSelected? 'detailSelected' : ''}`}
            >

            <div className="prospectus-overlay"></div>

            <Nav noSave={false} useClass="step-5" returnHome={returnHome} />

            <AnimatePresence>
            {
              ! isDetailSelected &&
              <div className="infront">
                  <motion.div
                  key="Details"
                  variants={authAnimation}
                  initial="initial"
                  animate="visible"
                  exit="exit"
                  layoutId="Details"
                  >
                    <div className="container">
                      <a className="inline-block" href="https://glos.ac.uk">
                        <picture>
                            <source type="image/webp" srcSet={logo} />
                            <source srcSet={logoFallback} />
                            <img
                              className="logo"
                              style={{ height: "40px" }}
                              src={logo}
                              alt="University of Gloucestershire logo"
                              title="Visit our website"
                            />
                        </picture>
                      </a>

                      <header className="step-1 heading prospectus-header">
                        <h2 className="is-3 color-white">Welcome to your prospectus</h2>
                        <p className="color-white is-7--mobile is-family-primary">
                          You can pick multiple options here! Searching will show you the
                          subject area the course belongs to.
                        </p>
                      </header>

                      <CardCarousel
                      title="Courses"
                      items={activeCourses}
                      canEdit={( canEdit || user.jwtAuthToken )}
                      inactive={inActiveCourses}
                      bucket="course"
                      search="course"
                      dummy={false}
                      type="post"
                      useClass="step-2"
                      />

                      <CardCarousel
                        title="Your campus"
                        // subtitle="Education and learning"
                        // text="Education and Learning is taught at the Park campus. Find out more about this campus and subject specific facilities below."
                        items={activeCampuses}
                        type="term"
                        canEdit={( canEdit || user.jwtAuthToken )}
                        inactive={inActiveCampuses}
                        bucket="campus"
                        search="campus"
                        dummy={false}
                        canToggle={false}
                        useClass="step-3"
                      />

                      <CardCarousel
                        title="Accommodation"
                        // subtitle="Education and learning"
                        // text="We've ordered your accommodation choices in order of what is closest to where your subject(s) is taught."
                        items={activeAccommodation}
                        canEdit={( canEdit || user.jwtAuthToken )}
                        inactive={inActiveAccommodation}
                        bucket="accommodation"
                        search="accommodation"
                        dummy={false}
                        type="post"
                      />

                      <CardCarousel
                        title="Your journey"
                        search="career"
                        items={activeCareer}
                        canEdit={( canEdit || user.jwtAuthToken )}
                        isMinimised={true}
                        canToggle={true}
                        inactive={inActiveCareer}
                        bucket="career"
                        dummy={true}
                        type="post"
                        loadItems={activeCareer.length > 0 || inActiveCareer.length > 0 }
                      />

                      <CardCarousel
                        title="Our home"
                        search="protecting"
                        items={activeProtect}
                        canEdit={( canEdit || user.jwtAuthToken )}
                        isMinimised={true}
                        canToggle={true}
                        inactive={inActiveProtect}
                        bucket="protecting"
                        dummy={true}
                        type="post"
                        loadItems={activeProtect.length > 0 || inActiveProtect.length > 0 }
                      />

                      <CardCarousel
                        title="Our community"
                        search="safety"
                        items={activeSafety}
                        canEdit={( canEdit || user.jwtAuthToken )}
                        isMinimised={true}
                        canToggle={true}
                        inactive={inActiveSafety}
                        bucket="safety"
                        dummy={true}
                        type="post"
                        loadItems={activeSafety.length > 0 || inActiveSafety.length > 0 }
                      />

                      <CardCarousel
                        title="Your safety"
                        search="fitting_in"
                        items={activeFitting}
                        canEdit={( canEdit || user.jwtAuthToken )}
                        isMinimised={true}
                        canToggle={true}
                        inactive={inActiveFitting}
                        bucket="fitting_in"
                        dummy={true}
                        type="post"
                        loadItems={activeFitting.length > 0 || inActiveFitting.length > 0 }
                      />

                      <CardCarousel
                        title="Your future"
                        search="clubs"
                        items={activeClubs}
                        canEdit={( canEdit || user.jwtAuthToken )}
                        isMinimised={true}
                        canToggle={true}
                        inactive={inActiveClubs}
                        bucket="clubs"
                        dummy={true}
                        type="post"
                        loadItems={activeClubs.length > 0 || inActiveClubs.length > 0 }
                      />

                      <CardCarousel
                        title="The planet"
                        search="support"
                        items={activeSupport}
                        canEdit={( canEdit || user.jwtAuthToken )}
                        isMinimised={true}
                        canToggle={true}
                        inactive={inActiveSupport}
                        bucket="support"
                        dummy={true}
                        type="post"
                        loadItems={activeSupport.length > 0 || inActiveSupport.length > 0 }
                      />


                      <CardCarousel
                        title="Discover Gloucestershire"
                        search="discover_glos"
                        items={activeDiscover}
                        canEdit={( canEdit || user.jwtAuthToken )}
                        isMinimised={true}
                        canToggle={true}
                        inactive={inActiveDiscover}
                        bucket="discover_glos"
                        dummy={true}
                        type="post"
                        loadItems={activeDiscover.length > 0 || inActiveDiscover.length > 0 }
                      />

                      <CardCarousel
                        title="International students"
                        search="international_students"
                        items={activeInternational}
                        canEdit={( canEdit || user.jwtAuthToken )}
                        isMinimised={true}
                        canToggle={true}
                        inactive={inActiveInternational}
                        bucket="international_students"
                        dummy={true}
                        type="post"
                        loadItems={activeInternational.length > 0 || inActiveInternational.length > 0 }
                      />

                      <CardCarousel
                        title="Fun facts"
                        search="fun_facts"
                        items={activeFacts}
                        canEdit={( canEdit || user.jwtAuthToken )}
                        isMinimised={true}
                        canToggle={false}
                        inactive={inActiveFacts}
                        bucket="fun_facts"
                        type="post"
                        dummy={true}
                        useClass="step-4"
                        loadItems={activeFacts.length > 0 || inActiveFacts.length > 0 }
                      />

                      <CardCarousel
                        title="Recommended for you"
                        items={activeRecommended}
                        canEdit={( canEdit || user.jwtAuthToken )}
                        dummy={true}
                        type="post"
                        bucket="recommended"
                        search="recommended"
                        inactive={inActiveRecommended}
                        loadItems={activeRecommended.length > 0 || inActiveRecommended.length > 0 }
                      />
                    </div>
                  </motion.div>
              </div>
          }

              {
                isDetailSelected &&
                  <motion.div
                  key="Details"
                  variants={authAnimation}
                  initial="initial"
                  animate="visible"
                  exit="exit"
                  layoutId="Details"
                  className="infront"
                  >
                      <Details selected={selectedDetail} items={listOfDetails} />
                  </motion.div>
              }

            </AnimatePresence>
            </motion.div>
          ) : ''
        }
        </AnimatePresence>
      </motion.div>
    );
});

export default Prospectus;