import { combineReducers } from 'redux';

// Import reducers here.
import userReducer from './userReducer';
import sidebarReducer from './sidebarReducer';
import courseReducer from './courseReducer';
import prospectusReducer from './prospectusReducer';

const rootReducer = combineReducers({
    user : userReducer,
    sidebar : sidebarReducer,
    course : courseReducer,
    prospectus: prospectusReducer,
});

export default rootReducer;

