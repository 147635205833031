import {prospectusUrl} from '../api';

const sendResetPassword = (password, resetToken, secret) => async(dispatch) => {

    // Run the await on recaptcha.
    const response = await fetch( prospectusUrl() + 'reset', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            password: password,
            resetToken: resetToken,
            secret: secret
        }),
    })

    const data = await response.json();

	if ( data ) {
        dispatch({type : 'RESET_PASSWORD'})
    } else {
        dispatch({type : 'FAILED_RESET_PASSWORD'})
    }
}

export default sendResetPassword;
