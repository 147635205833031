import {clickedEnter} from '../../helpers/clickedEnter';

const Bubble = (props) => {

  const setBubble = (e) => {

    if ( ! clickedEnter(e) ) {
      return;
    }

    props.setBubble( props.slug );
  };

	return (
      <div
        className={`bubble__inner${props.isActive ? ' active' : ''}`}
        onClick={setBubble}
        onKeyPress={setBubble}
        tabIndex="0"
        aria-label={`Add ${props.title} to your option to your multiselect`}>
        <p className="is-family-secondary no-margin title is-7">
          {props.title}
        </p>
      </div>
  );
};

export default Bubble;
