import {getItemsToAppend, removeItemsFromBin, getTermsBySlug, getStatesToUpdate} from '../helpers';

const initState = {
    canEdit: true,
    selector: false,
    phases: 2,
    updated: false,
    loadedProspectus: false,
    hasMainCookie: document.cookie.replace(/(?:(?:^|.*;\s*)hideTooltipsMain\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
    hasFactsCookie: document.cookie.replace(/(?:(?:^|.*;\s*)hideTooltipsFacts\s*\=\s*([^;]*).*$)|^.*$/, "$1"),

    factIndexes: [],

    inActiveCourses: [],
    inActiveCampuses: [],
    inActiveAccommodation: [],
    inActiveRecommended: [],

    campuses: [],
    activeCampuses: [],
    activeCourses: [],
    activeAccommodation: [],
    activeRecommended: [],
    activeCareer: [],
    inActiveCareer: [],
    activeSafety: [],
    inActiveSafety: [],
    activeProtect: [],
    inActiveProtect: [],
    activeFitting: [],
    inActiveFitting: [],
    activeClubs: [],
    inActiveClubs: [],
    activeSupport: [],
    inActiveSupport: [],

    activeDiscover: [],
    inActiveDiscover: [],
    activeInternational: [],
    inActiveInternational: [],
    loadedFacts: [],
    activeFacts: [],
    inActiveFacts: [],

    accommodation: [],
    potentialCourses: [],
    subjectAreas: [],
    isLoading: true,
    searchedTerms: [],
    isSearching: false,
    searchLoading: false,

    isDetailSelected: false,
    selectedDetail: [],
    listOfDetails: [],
};

const prospectusReducer = ( state = initState, action ) => {

    switch (action.type) {
        case 'FINISH_TOOLTIPS_MAIN' :

            document.cookie = "hideTooltipsMain=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";

            return{
                ...state,
                hasMainCookie: true
            }
        case 'FINISH_TOOLTIPS_FACTS':

            document.cookie = "hideTooltipsFacts=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";

            return {
                ...state,
                hasFactsCookie: true,
            }
        case 'CLOSE_DETAILS':
            return {
                ...state,
                isDetailSelected: false,
            }
        case 'SELECT_DETAILS':

            return {
                ...state,
                isDetailSelected: true,
                selectedDetail: action.payload.active,
                listOfDetails: action.payload.items
            }
        case 'DETAILS_FACTS':

            let selected =  state.loadedFacts[0];
                selected.node['sectionTitle'] = 'Fun Facts';

            return{
                ...state,
                isDetailSelected: true,
                selectedDetail: selected,
                listOfDetails: state.loadedFacts
            }
        case 'STARTED_SEARCH':
            return{
                ...state,
                searchLoading: true,
                isSearching: true,
            }
        case 'LOAD_ME' :
            return{
                ...state,
                activeRecommended: action.payload.recommended,
                activeFacts: action.payload.facts,
            }
        case 'EMPTY_TERM_SEARCH':
            return{
                ...state,
                isSearching: false,
                searchLoading: false,
            }
        case 'SEARCH_TERM_BY_COURSE':

            const foundTerms = getTermsBySlug( action.payload.results, state.subjectAreas );

            return{
                ...state,
                searchedTerms: foundTerms,
                searchLoading: false
            }
        case 'SET_SUBJECTS':
            return {...state, subjectAreas: action.payload.subjectAreas, isLoading: false};
        case 'SET_ACCOMMODATION':
            return {...state, accommodation: action.payload.accommodation, isLoading: false};
        case 'SET_CARDS':

            const setCard = getStatesToUpdate( action.payload.type );

            return{
                ...state,
                [setCard['active']]: action.payload.data
            }
        case 'FINISHED_UPDATE':
            return{
                ...state,
                updated: false,
            }
        case 'HAS_UPDATED_CARDS':

        return{
            ...state,
            selector: false,
            isSearching: false,
        }
        case 'SET_SUBJECT_COURSES':
            return {...state, potentialCourses: action.payload.courses, isLoading: false};
        case 'USE_SELECTOR':
            return {
                ...state,
                selector: true,
                phases: action.payload.phases
            };
        case 'APPEND_ACCOMMODATION':

            // The continue below.
            const newAccom = getItemsToAppend( action.payload, state.activeAccommodation );
            const newActiveAccom = state.activeAccommodation.concat( newAccom );

            const updatedBinAccom  = removeItemsFromBin( action.payload, state.inActiveAccommodation );

            return{
                ...state,
                activeAccommodation: newActiveAccom,
                inActiveAccommodation: updatedBinAccom,
                updated: true,
            }
        case 'APPEND_MY_COURSES':

            /**
             * Foreach new item added check if it is already set.
             */
            const newCampuses = getItemsToAppend( action.payload.activeCampuses, state.activeCampuses );
            const newCourses  = getItemsToAppend( action.payload.activeCourses, state.activeCourses );

            const newActiveCampuses = state.activeCampuses.concat( newCampuses );
            const newActiveCourses  = state.activeCourses.concat( newCourses );

            // Also check if we should be removing  items from the bin here.
            const updatedBinCampus  = removeItemsFromBin( action.payload.activeCampuses, state.inActiveCampuses );
            const updatedBinCourses = removeItemsFromBin( action.payload.activeCourses, state.inActiveCourses );

            return {
                ...state,
                campuses: action.payload.campuses,
                activeCampuses: newActiveCampuses,
                activeCourses: newActiveCourses,
                updated: true,
                inActiveCampuses: updatedBinCampus,
                inActiveCourses: updatedBinCourses
            };
        case 'TRASH_CARD':

            const trashStates = getStatesToUpdate( action.payload.bucket );
            const active = trashStates['active'];
            const bin = trashStates['bin'];

            return {
                ...state,
                [active]: action.payload.updatedItems ? action.payload.updatedItems : state[active],
                [bin]: [...state[bin], action.payload.currentItem],
            }
            case 'INIT_SELECTOR_LOADING':
                return{
                    ...state,
                    isLoading: true,
                }
            case 'RESTORE_CARD':

                const restorestate = getStatesToUpdate( action.payload.bucket );
                const activeRes = restorestate['active'];
                const binRes = restorestate['bin'];

                return{
                    ...state,
                    [activeRes] : [...state[activeRes], action.payload.currentItem],
                    [binRes] : action.payload.updatedItems ? action.payload.updatedItems : state[binRes],
                    factIndexes: 'undefined' !== typeof action.payload.index ? [...state.factIndexes, action.payload.index] : state.factIndexes
                };
            case 'INIT_PROSPECTUS':
                return{
                    ...state,
                    loadedProspectus: true,
                }
            case 'LOADED_PROSPECTUS':
                return action.payload.data;
            default:
            return {...state};
    }
}

export default prospectusReducer;