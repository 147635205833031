import {authAnimation} from '../animations';
import {motion} from 'framer-motion';
import {Link} from 'react-router-dom';

import pageTitleImg from '../img/404.svg';
import LogoHeader from '../components/LogoHeader';

const FourOhFour = () => {

    return(
        <motion.div
        key="coursesPage"
        variants={authAnimation}
        initial="initial"
        animate="visible"
        exit="exit"
        className={`courses transform grid`}
        >

            <LogoHeader />

            <div class="container">
                <img src={pageTitleImg} style={{height: '84px'}} title="404" alt="Fancy text that reads 404" />
                <h2 className="is-family-tertiary is-4">We're sorry, we can't find that page!</h2>
                <p>Head back to the homepage and go from there.</p>
                <Link
                    className="button login"
                    to={{
                    pathname: '/',
                }}>Return to home</Link>
            </div>
        </motion.div>
    )
}

export default FourOhFour;