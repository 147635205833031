import {apiUrl} from '../api';


// Double arrow function so that we can use thunk to do an async call.
const getSubjectAreas = (subject_areas) => async (dispatch) => {

    if ( 'undefined' === typeof subject_areas || 0 === subject_areas.length ) {
        const response = await fetch( apiUrl(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                { query: `
                    query {
                        subjectAreas(first: 500) {
                            edges {
                                node {
                                    name
                                    slug
                                }
                            }
                        }
                    }
                `}
            ),
        })

        const data = await response.json();
        subject_areas = data.data.subjectAreas.edges;
    }

    dispatch({
        type: 'SET_SUBJECTS',
        payload: {
            subjectAreas : subject_areas
        }
    })
}

export default getSubjectAreas;