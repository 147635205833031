import {useEffect, useState, Fragment} from "react";
import chevron from '../../img/icons/dropdown.svg'
import arrow from '../../img/icons/arrow-left.svg'
import tick from '../../img/icons/tick.svg';

import getCards from '../../actions/getCards';

import { useDispatch } from "react-redux";

const CarouselTitle = ({title, isMain, hasItems, canToggle, loadItems, search}) => {

    const [checkboxId, setCheckboxId] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        setCheckboxId( Math.random() );
    }, []);

    /**
     * Run a fetch request for data &&/|| send the user to the detail screen.
     */
    const handleSetUpClick = (e) => {

        if ( e ) {
            if ( e.charCode && e.charCode !== 13 ) {
                return;
            }
        }

        if ( !hasItems ) {

            if ( 'campus' !== search ) {
                dispatch({
                    type: 'USE_SELECTOR',
                    payload: {
                        phases: 'course' === search ? 2 : 1
                    }
                })
            }
        }
    }

    const handleGetCards = () => {
        if ( !loadItems ){
            dispatch( getCards(search) );
        }
    }

    function hideSection() {
        return 'campus' === search || 'recommended' === search || 'fun_facts' === search;
    }

    return(

        <Fragment>
            { !canToggle ? (
                <h2 className={`${!hideSection() ? 'cursor' : ''} ${isMain && hasItems && 'recommended' !== search? 'ticked' : ''} ${isMain ? 'title is-3 is-4--mobile' : 'is-5 is-5--mobile'}`} tabIndex={!hideSection() ? '0' : '-1'} onKeyPress={handleSetUpClick} onClick={handleSetUpClick}>
                    <span>{title}</span>
                    {isMain && ! hasItems && !hideSection() && <img src={arrow} className="img arrow" alt="Arrow icon" />}
                    {isMain && hasItems && !hideSection() && <img src={tick} className="img tick" alt="Green tick icon" />}
                </h2>
            ) : (
                <Fragment>
                    <input type="checkbox" className="carousel_checkbox hidden" id={checkboxId} onChange={handleGetCards} />
                    <label htmlFor={checkboxId} className={isMain ? 'title is-3 is-4--mobile is-family-secondary' : 'is-5 is-family-secondary is-5--mobile'}>
                        <span>{title}</span>
                        <img src={chevron} className="img chev" alt="Chevron icon" />
                    </label>
                </Fragment>
            )}
        </Fragment>
    )
}

export default CarouselTitle;