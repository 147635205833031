import {campusurl} from '../api';

// TAKE THE COURSE CAMPUSES,
// GRAB THE DATA FOR THEM,
// && APPEND THAT ALONGSIDE THE COURSE DATA TO THE PROSPECTUS
const setCourses = ({secondaryItems, campuses, potentialCourses}) => async (dispatch) => {

    let itemCampuses = [],
        itemSubjects = [],
        activeCourses = [];

    // Loop over the courses && get their campus.
    potentialCourses.forEach(item => {
        if ( secondaryItems.includes( item.node.slug ) ) {
            // Select the campuses.
            item.node.campuses.edges.forEach(campus => {
                itemCampuses.push( campus.node.slug );
            });

            // .
            item.node.subjectAreas.edges.forEach(subject => {
                itemSubjects.push( subject.node.slug );
            });

            // Select our course.
            activeCourses.push( item );
        }
    });

    // Potentially grab the campus data if we don't have it.
    if ( 'undefined' === typeof campuses || 0 === campuses.length ) {
        const response = await fetch( campusurl() + '?campuses=' + itemCampuses.join() + '&subject_areas=' + itemSubjects.join(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();

        campuses = 'undefined' !== typeof data.results ? data.results.edges : [];

        dispatch({
            type: 'APPEND_MY_COURSES',
            payload: {
                activeCourses : activeCourses,
                activeCampuses: campuses,
                campuses : campuses,
                updated: true,
            }
        })
    } else {
        dispatch({
            type: 'APPEND_MY_COURSES',
            payload: {
                activeCourses : activeCourses,
                activeCampuses: campuses,
                campuses : campuses,
                updated: true,
            }
        })
    }
}

export default setCourses;