import {useState} from 'react';

import logo from '../img/logo.webp';
import logoFallback from '../img/logo.png';
import prospectusText from '../img/my-prospectus.svg';

import Selector from './selector'

import {useDispatch} from 'react-redux';
import {openSidebar} from '../actions/openSidebar';

import { Link } from "react-router-dom"

import {AnimatePresence, motion} from 'framer-motion';

import {authAnimation, homePageAnimation} from '../animations';

const Home = () => {

    const [selector, setSelector] = useState(false);
    const [hideHome, setHideHome] = useState(false);

    const dispatch = useDispatch();

    const loadExperienceHandler = () => {

        setSelector(true)

        setTimeout(() => {
            setHideHome(true)
        }, 600);
    }

    const openSidebarHandler = (e) => {
        e.preventDefault();
        dispatch( openSidebar( 'login' ) );
    }

    const closeSelector = () => {
        setHideHome( false );

        setTimeout(() => {
            setSelector( false );
        }, 600);
    }

    return (
        <motion.div
        key="home"
        variants={homePageAnimation}
        initial="initial"
        animate="visible"
        exit="exit"
        className="">

            <AnimatePresence>
            {
                selector ? (
                    <Selector numOfPhases={2} closeSelector={closeSelector} />
                ):''
            }

            {
                !selector || !hideHome ? (
                    <motion.div
                        key="homeSelector"
                        variants={authAnimation}

                        exit="exit"
                        className={`home ${hideHome ? 'hide' : ''}`}>
                        <div className="container">
                            <a className="inline-block" rel="noreferrer" target="_blank" href="https://glos.ac.uk">
                                <picture>
                                    <source type="image/webp" srcSet={logo} />
                                    <source srcSet={logoFallback} />
                                    <img className="logo" style={{height: '62px'}} src={logo} alt="University of Gloucestershire logo" title="Visit our website" />
                                </picture>
                            </a>
                            <img src={prospectusText} style={{height: '84px'}} alt="My Glos prospectus" />

                            <p>Find out more about our forward-thinking, supportive university community, by creating your very own personalised prospectus.</p>

                            <div className="home--footer">
                                <div className="enter">
                                        <button onClick={loadExperienceHandler} className="button">Create now</button>

                                        <p className="is-family-primary">OR</p>

                                        <Link
                                            className="underlined cursor dark is-family-secondary"
                                            to={{
                                            pathname: '/everything',
                                            state: { previous: 'home' }
                                        }}>Show me everything</Link>
                                </div>

                                <p className="is-family-primary">Already have an account? <button className="cursor btn underlined is-family-secondary" tabIndex="0" onClick={openSidebarHandler}>Login</button></p>
                            </div>
                        </div>
                    </motion.div>
                ) : ''
            }
            </AnimatePresence>
        </motion.div>
    )
}

export default Home;