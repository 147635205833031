import {prospectusUrl} from '../api';

const loadProspectus = (code, token) => async (dispatch) => {

    const response = await fetch( prospectusUrl() + 'load', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            code: code,
            prospectus_code: token
        }),
    })

    const data = await response.json();

    if ( !token || '' === token ) {
        data.data.canEdit = false;
    }

    data.data.loadedProspectus = true;

    dispatch({
        type: 'LOADED_PROSPECTUS',
        payload: data
    })
}

export default loadProspectus;