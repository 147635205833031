import {alphabetizeData, getAlphabetStates} from '../helpers';

const initState = {
    courses: [],
    career: [],
    protecting: [],
    safety: [],
    fitting_in:[],
    clubs: [],
    support: [],
    fun_facts: [],
    international_students: [],
    discover: [],
    accommodation: [],
    coursesAlphabetical: [],
    discoverAlphabetical: [],
    accommodationAlphabetical: [],
    careerAlphabetical: [],
    protectingAlphabetical: [],
    safetyAlphabetical: [],
    fittingInAlphabetical: [],
    clubsAlphabetical: [],
    supportAlphabetical: [],
    funFactsAlphabetical: [],
    internationalAlphabetical: [],
    searchedCourses: {},
    searchedDiscover: {},
    searchedAccommodation: {},
    searchedCareer: {},
    searchedProtecting: {},
    searchedSafety: {},
    searchedFittingIn: {},
    searchedClubs: {},
    searchedSupport: {},
    searchedFunFacts: {},
    searchedInternational: {},
    isLoading: true,
    isSearch: false,
    activeSearch: [],
    searches: [],
    searchType: 'courses',
    searchTerm : '',
    previous: '',
    scrollLoading: false,
};

const courseReducer = ( state = initState, action ) => {

    switch (action.type) {
        case 'SCROLL_LOADING':
            return{
                ...state,
                scrollLoading: action.payload
            }
        case 'SEARCH_DATA':

            const searchData = getAlphabetStates( action.payload.type );

            let updateSearch = {};
            updateSearch[action.payload.term] = action.payload.result;

            return{
                ...state,
                isSearch: true,
                activeSearch: action.payload.result,
                isLoading: false,
                searchTerm: action.payload.term,
                searches: [...state.searches, {'type' : action.payload.type, 'term' : action.payload.term}],
                [searchData['search']]: {...state[searchData['search']], ...updateSearch}
            }
        case 'FETCH_DATA':

            // Find out where we need to append the new data.
            const data = getAlphabetStates( action.payload.type );

            // Sort the data.
            const newData          = state[data['cpt']].concat( action.payload.data );
            const alphabetizedData = action.payload.data ? alphabetizeData( newData ) : ''

            // Set the data.
            return{
                ...state,
                [data.cpt]: newData,
                [data.alphabet]: alphabetizedData,
                isLoading: false,
                isSearch: false,
                scrollLoading: false,
                previous: action.payload.data.length > 0 ? action.payload.data[action.payload.data.length - 1].cursor : ''
            };
        case 'LOADING':
            return{
                ...state,
                isLoading: action.payload,
            }
        case 'SEARCH_TYPE':
            return{
                ...state,
                searchType: action.payload.type,
            }
        case 'PREVIOUS_SEARCH':
            return{
                ...state,
                isLoading: false,
                isSearch: true,
                searches: [...state.searches, {'type' : action.payload.type, 'term' : action.payload.term}],
                activeSearch: action.payload.result,
            }

        case 'EMPTY_SEARCH':
            return{
                ...state,
                isSearch: false,
                isLoading: false,
                searchTerm: '',
            }

        default:
            return {...state};
    }
}

export default courseReducer;
