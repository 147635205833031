const LoadingScreen = ( {content, isLoading} ) => {

    return(
        <div className={`loadingSelector${isLoading ? '' : ' hidden'}`}>
            <h2>Hold tight!</h2>
            <p>{content}</p>
        </div>
    )
}

export default LoadingScreen;