import logo from '../img/glos-logo-white.webp';
import logoFallback from '../img/glos-logo-white.png';
import {memo} from 'react';

const LogoHeader = memo(() => {

    return(
        <div className="logo_head">
            <a href="https://glos.ac.uk" target="_blank" rel="noreferrer">
                <picture>
                    <source type="image/webp" srcSet={logo} />
                    <source srcSet={logoFallback} />
                    <img src={logo} style={{height: '40px'}} alt="University of Gloucestershire logo" title="University of Gloucestershire" />
                </picture>
            </a>
        </div>
    )
})

export default LogoHeader