import {cardUrl} from '../api';
import {fetchCards} from '../helpers';

const initialDataLoad = (location) => async (dispatch) => {

    const pathId = location.pathname.split("/")[2];

    /**
     * Are loading a previously saved prospectus,
     * or starting a new one.
     */
    if ( 'me' === pathId ) {

        const [facts, recommended] = await Promise.all([fetchCards( 'fun_facts', cardUrl() ), fetchCards( 'recommended', cardUrl() )])

        dispatch({
            type : 'LOAD_ME',
            payload: {
                facts : facts.results.edges,
                recommended : recommended.results.edges
            }
        })
    }
}

export default initialDataLoad;