import {apiUrl, cardUrl} from '../api';
import {fetchCards} from '../helpers';

// Double arrow function so that we can use thunk to do an async call.
export const loadCourses = (term, type, bucket = '', previousCursor) => async (dispatch) => {

    let query = '',
        data = [],
        result = '',
        finalResult = '',
        ppp = '';

    if ( undefined === type || '' === type ) {
        type = 'courses';
    }

    if ( undefined !== term && '' !== term ) {
        query = `(
            where:  {
                orderby: {
                    field: TITLE,
                    order: ASC
                },
                metaQuery: {
                    metaArray: {
                        key: "pageName",
                        value: "${term}",
                        compare: LIKE
                    }
                },
                taxQuery: {
                    taxArray: {
                        terms: ["ugt"],
                        taxonomy: STUDY_LEVEL,
                        operator: IN,
                        field: SLUG
                    }
                }
            }
        )`;
    } else {
        ppp = previousCursor ? `(
            where: {
                orderby: {
                    field: TITLE,
                    order: ASC
                },
                taxQuery: {
                    taxArray: {
                        terms: ["ugt"],
                        taxonomy: STUDY_LEVEL,
                        operator: IN,
                        field: SLUG
                    }
                }
            },
            first: 24,
            after: "${previousCursor}"
        )` : `(
            where: {
                orderby: {
                    field: TITLE,
                    order: ASC
                },
                taxQuery: {
                    taxArray: {
                        terms: ["ugt"],
                        taxonomy: STUDY_LEVEL,
                        operator: IN,
                        field: SLUG
                    }
                }
            },
            first: 24
        )`;
    }

    // If type not courses then fetch & search locally.
    if ( 'courses' === type ) {
        // Fetch  request here.
        const response = await fetch( apiUrl(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                { query: `
                    query {
                        courses${query}${ppp} {
                            edges {
                                cursor
                                node {
                                    databaseId
                                    title
                                    uri
                                    link
                                    mediaFeaturedImageUrl
                                    api_fields {
                                        courseDescription
                                    }
                                    featuredImage {
                                        node {
                                            mediaDetails {
                                                sizes {
                                                    sourceUrl
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }`
                }
            ),
        })
        data = await response.json();
    } else {

        if ( '' === bucket ) {
            const data = await fetchCards( type, cardUrl() );
            result = data.results.edges;
        } else {
            result = bucket;
        }

        if ( undefined !== term && '' !== term ) {
            var search = new RegExp(term , 'i');
            result = result.filter(item => search.test(item.node.title));
        }
    }

    // Dependant on the parms provided fire off a different request to the reducer
    if ( query ) {

        finalResult = 'courses' === type ? data.data.courses.edges : result;

        dispatch({
            type : 'SEARCH_DATA',
            payload : {
                type: type,
                term: term,
                result: finalResult
            },
        })
    } else {

        finalResult = 'courses' === type ? data.data.courses.edges : result;

        dispatch({
            type : 'FETCH_DATA',
            payload : {
                type: type,
                data: finalResult,
            },
        })
    }
}
