import {cardUrl} from '../api';


// Double arrow function so that we can use thunk to do an async call.
const getAccommodation = (accommodation) => async (dispatch) => {

    if ( 'undefined' === typeof accommodation || 0 === accommodation.length ) {
        const response = await fetch( cardUrl() + '?section=accommodation', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })

        const data = await response.json();
        accommodation = data.results.edges;
    }

    dispatch({
        type: 'SET_ACCOMMODATION',
        payload: {
            accommodation : accommodation
        }
    })
}

export default getAccommodation;