import Tooltip from '../components/Tooltips/Tooltip'

const steps = [
	{
		selector: '.step-1',
		style: {
			backgroundColor: 'rgba( 255, 255, 255, 0 )',
			padding: '6px',
		},
		content: ({ goTo, inDOM, close }) => (
			<Tooltip
				className="show"
				styleName="one"
				title="Welcome to your prospectus"
				text="We've added your selected courses below!"
				buttonTxt="Next step"
				next="two"
				skip
				actionNext={(e) => goTo(1)}
				actionClose={(e) => { close(); document.getElementById('prospectus-main').scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				})}}
			/>
		)
	},
	{
		selector: '.step-2',
		style: {
			backgroundColor: 'rgba( 255, 255, 255, 0 )',
			padding: '6px',
		},
		position: 'bottom',
		content: ({ goTo, inDOM, close }) => (
			<Tooltip
				styleName="two"
				title="Swipe"
				text="View all your selected courses by swiping to the left."
				buttonTxt="Next step"
				next="three"
				prev="one"
				skip
				back
				actionPrevious={(e) => goTo(0)}
				actionNext={(e) => goTo(2)}
				actionClose={(e) => { close(); document.getElementById('prospectus-main').scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});}}
			/>
		)
	},
	{
		selector: '.step-3',
		style: {
			backgroundColor: 'rgba( 255, 255, 255, 0 )',
			padding: '6px',
		},
		position: 'bottom',
		content: ({ goTo, inDOM, close }) => (
			<Tooltip
				styleName="three"
				title="Your campus"
				text="We've collated some campus related cards based on your subject area choices."
				buttonTxt="Next step"
				next="four"
				prev="two"
				skip
				back
				actionPrevious={(e) => goTo(1)}
				actionNext={(e) => goTo(3)}
				actionClose={(e) => { close(); document.getElementById('prospectus-main').scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});}}
			/>
		)
	},
	{
		selector: '.step-4',
		style: {
			backgroundColor: 'rgba( 255, 255, 255, 0 )',
			padding: '6px',
		},
		position: 'bottom',
		content: ({ goTo, inDOM, close }) => (
			<Tooltip
				styleName="four"
				title="Fun facts"
				text="Check out the weird and wonderful things we offer here at UoG! Tap on inspire me and see what it shows up. Click the + to save it to your prospectus."
				buttonTxt="Next step"
				next="five"
				skip
				back
				prev="three"
				actionPrevious={(e) => goTo(2)}
				actionNext={(e) => goTo(4)}
				actionClose={(e) => { close(); document.getElementById('prospectus-main').scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});}}
			/>
		)
	},
	{
		selector: '.step-5',
		style: {
			backgroundColor: 'rgba( 255, 255, 255, 0 )',
			padding: '6px',
		},
		content: ({ goTo, inDOM, close }) => (
			<Tooltip
				styleName="five"
				title="The menu"
				text="From here you can return to the homepage, save and share your prospectus, chat with us and also start over (if you need to)."
				buttonTxt="Complete"
				finish
				back
				prev="four"
				actionPrevious={(e) => goTo(3)}
				actionClose={(e) => { close(); document.getElementById('prospectus-main').scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});}}
			/>
		)
	},
];

export default steps;