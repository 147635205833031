const prospectus = process.env.NODE_ENV === 'development' ? 'https://smile.local/prospectus' : 'https://glos.production.wearesmile.com/prospectus';
const courses = process.env.NODE_ENV === 'development' ? 'https://smile.local/glos-courses' : 'https://www.glos.ac.uk/courses';

export const apiUrl = () => `${courses}/graphql/`;
export const prospectusGraphql = () => `${prospectus}/graphql/`;

// /wp-json/wp/prospectus/cards?section=discover_glos
export const cardUrl = () => `${prospectus}/wp-json/wp/prospectus/cards/`;
export const campusurl = () => `${prospectus}/wp-json/wp/prospectus/campus/cards/`

export const prospectusUrl = () => `${prospectus}/wp-json/jwt-auth/v1/prospectus/`;

export const wp_social = () => `${prospectus}/wp-json/wp/prospectus/user/register/`;
