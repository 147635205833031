export const authAnimation = {
    initial: {
        opacity: 0,
        transition: { ease: 'easeInOut', delay: 0, duration: 0.5 }
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: { ease: 'easeInOut', delay: 0, duration: 0.5 }
    },
    exit: {
        opacity: 0,
        transition: { ease: 'easeInOut' }
    }
};

export const pageAnimation = {
    initial: {
        x: '100vw',
        transition: { ease: 'easeInOut', delay: 0, duration: 0.5 }
    },
    visible: {
        x: 0,
        transition: { ease: 'easeInOut', delay: 0, duration: 0.5 }
    },
    exit: {
        x: '100%',
        transition: { ease: 'easeInOut', delay: 0, duration: 0.5 }
    }
};

export const homePageAnimation = {
    initial: {
        opacty: 0,
        transition: { ease: 'easeInOut', delay: 0, duration: 0.5 }
    },
    visible: {
        opacty: 1,
        transition: { ease: 'easeInOut', delay: 0, duration: 0.5 }
    },
    exit: {
        opacty: 0,
        transition: { ease: 'easeInOut', delay: 0, duration: 0.5 }
    }
};