const hasNumber = value => {
	return new RegExp(/[0-9]/).test(value);
}
const hasMixed = value => {
	return new RegExp(/[a-z]/).test(value) &&
	new RegExp(/[A-Z]/).test(value);
}
const hasSpecial = value => {
	return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
}
export const strengthColor = count => {
	if (false === count)
		return '';
	if (count < 3)
		return 'fail';
	if (count < 5)
		return 'good';
	if (count < 6)
		return 'pass';
}
export const strengthIndicator = value => {

	if ('' === value)
		return false;

	let strengths = 0;
	if (value.length > 5)
		strengths++;
	if (value.length > 7)
		strengths++;
	if (hasNumber(value))
		strengths++;
	if (hasSpecial(value))
		strengths++;
	if (hasMixed(value))
		strengths++;

	return strengths;
}