import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import Bubble from "./Bubble";

import {onMouseMove, animateChildren} from '../../helpers';

import {useDispatch} from 'react-redux';

import fetchCoursesByTerm from '../../actions/fetchCoursesByTerm';
import setCourses from '../../actions/setCourses';
import setAccommodation from '../../actions/setAccommodation';

const BubbleScroll = ({items, numOfPhases, phase, setPhase, activeType, potentialCourses, setLastLoad, hide}) => {

  let lastFrame;

  const dispatch = useDispatch();

  const {campuses} = useSelector((state) => state.course)

  // These two arrays hold the selected bubble state for phase 1 & 2.
  const [selectedItems, setItems] = useState([]); // The items selected in phase 1.
  const [secondaryItems, setSecondaryItems] = useState([]); // items selected in phase 2.

  const scrollAnimation = () => {
    const screenCenter = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2
    }

    animateChildren(screenCenter);
  }

  const scrollAni = (e) => {
    window.requestAnimationFrame( (thisFrame) => {
      if ((thisFrame - lastFrame) > 16) {
        const screenCenter = {
          x: window.innerWidth / 2,
          y: window.innerHeight / 2
        }
        animateChildren(screenCenter);

      }
    })
  }

  const mouseMove = (e) => {
    window.requestAnimationFrame( (thisFrame) => {
      if ((thisFrame - lastFrame) > 16) {

        animateChildren(e)
        onMouseMove( e );
      }
      lastFrame = thisFrame;
    } )
  }

  useEffect(() => {
    const wrap = document.getElementById('bubble__wrapper');

    document.addEventListener( 'mousemove', mouseMove, true )
    wrap.addEventListener( 'scroll', scrollAnimation, true )

    return () => {
      document.removeEventListener( 'mousemove', mouseMove, true )
      wrap.removeEventListener( 'scroll', scrollAnimation, true )
    }

  }, []);

  /**
   * On click of continue, check we have items,
   * if so set a loading screen & fetch the data.
   */
  const handleContinueClick = (e) => {

    if ( 1 === phase ) {
      if ( selectedItems.length > 0 ) {

        /**
         * If only one, then we are setting the selected accomodation.
         * Else we are fetching courses.
         */
        if ( 1 === numOfPhases ) {
          dispatch( setAccommodation(selectedItems, items) );
        } else {
          dispatch( fetchCoursesByTerm( selectedItems ) )
          setPhase(2);
        }

        dispatch({ type : 'INIT_SELECTOR_LOADING' });
      } // maybe error?
    } else {
      if ( secondaryItems.length > 0 ) {

        setLastLoad();

        // Once the user has selected courses, set them in the prospectus.
        dispatch( setCourses({secondaryItems, campuses, potentialCourses}) )
      } // maybe error?

      dispatch({ type : 'INIT_SELECTOR_LOADING' });
    }
  }

  const setBubbleActive = (slug, item) => {

    if ( 1 === phase ) {
      addOrRemoveSelection( selectedItems, slug, 1 );
    } else {
      addOrRemoveSelection( secondaryItems, slug, 2 );
    }
  }

  const addOrRemoveSelection = ( bucket, slug, setfunc ) => {
    if ( bucket.includes(slug) ) {

      const filteredItems = bucket.filter(function(item) {
        return item !== slug
      })

      // remove from the correct bucket.
      if ( 1 === setfunc ) {
        setItems( filteredItems );
      } else {
        setSecondaryItems( filteredItems );
      }
    } else {

      // Set the correct bucket.
      if ( 1 === setfunc ) {
        setItems([...bucket, slug]);
      } else {
        setSecondaryItems([...bucket, slug]);
      }
    }
  }

	return (
    <div className={hide ? 'hidden' : ''}>
      <a href="#continue-to-next" className="skip-to">Skip down past options to continue button</a>
      <div className={`bubble__wrapper ${1 === phase ? 'one' : 'two'}`} id="bubble__wrapper">
            <div className={`bubble__scroll ${1 === phase ? 'one' : 'two'} ${items && items.length > 3 ? '' : 'single-items'}`} id="bubble__scroll">
          {
            items && items.map(
              (item, key) => {
                return (
                  'term' === activeType ? (
                    <div className="bubble__item-wrap" key={key+'term'}>
                      <Bubble title={item.node.name} slug={item.node.slug} setBubble={setBubbleActive} isActive={phase === 1 ? selectedItems.includes(item.node.slug) : secondaryItems.includes(item.node.slug)} />
                    </div>
                  ) :  (
                    <div className="bubble__item-wrap" key={key+'post'}>
                      <Bubble title={item.node.title} slug={item.node.slug} setBubble={setBubbleActive} isActive={phase === 1 ? selectedItems.includes(item.node.slug) : secondaryItems.includes(item.node.slug)} />
                    </div>
                  )
                )
              }
            )
          }
        </div>
      </div>
      <a href="#go-back" className="skip-to">Skip up past options to back button</a>
      <button id="continue-to-next" aria-label="If you have selected at least one bubble above, clicking here will continue to the next stage" className={`button continue login cursor ${(phase===1&&selectedItems.length<1) || (phase===2&&secondaryItems.length<1)?'disabled':''}`} onClick={handleContinueClick}>Continue</button>
    </div>
  );
};

export default BubbleScroll;
