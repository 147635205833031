import {useDispatch} from 'react-redux';

import IconPlusCircle from '../Icons/IconPlusCircle';

import {clickedEnter} from '../../helpers/clickedEnter';

const InsertCard = ({bucket}) => {

    const dispatch = useDispatch();

    const insertMore = (e) => {

        if ( !clickedEnter(e) ) {
            return;
        }

        dispatch({
            type: 'USE_SELECTOR',
            payload: {
                phases: 'course' === bucket ? 2 : 1
            }
        })
    }

    return (
        <article className="card card--more is-flex" tabIndex="0" onKeyPress={insertMore} onClick={insertMore}>
            <IconPlusCircle width="20" height="20" color="#ffffff" />
            <h4 className="is-6">Add more!</h4>
            <p className="is-7"> Refine your selection further</p>
        </article>
    )
}

export default InsertCard;