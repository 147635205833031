import {memo, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {loadCourses} from '../../actions/loadCourses';

import { getAlphabetStates } from '../../helpers';

import searchIcon from '../../img/icons/search.svg';

const Header = memo(() => {

    const [term, setTerm] = useState('');

    const course = useSelector((state) => state.course)

    const {
        isLoading,
        searchType,
        searchTerm
    } = useSelector((state) => state.course)

    const dispatch = useDispatch();

    // On initial load, set the term to the global term.
    useEffect(() => {
        // set the search term on mount.
        setTerm( searchTerm );
    }, [])

    /**
     * When we leave this view,
     * make sure we return to the same search term.
     */
    useEffect(() => {

        // Component will unmount.
        return function cleanup() {
            dispatch({ type : 'UPDATE_TERM', payload: {term: term} })
        };
    }, [searchTerm, term, dispatch]);

    const removeFromString = ( arr,str ) => {
        let regex = new RegExp("\\b"+arr.join('|')+"\\b","gi")
        return str.replace(regex, '')
    }

    /**
     * Submitted the form.
     */
    const submitCourseSearch = (e) => {
        e.preventDefault();

        if ( isLoading || term.replace(/\s+/g, '').length < 3 ) {
            return;
        }

        const searched = previouslySearched( searchType );
        if ( searched ) {
            dispatch({ type : 'LOADING', payload: true });

            setTimeout(() => {
                dispatch({ type : 'LOADING', payload: false });
            }, 1500);
            return
        }

        let filteredTerm = removeFromString(
            [
                'Ba (Hons)',
                'BSc (Hons)',
                '(Hons)',
                'BA',
            ],
            term
        );

        filteredTerm = filteredTerm.replace(/[^a-z-A-Z ]/g, "").replace(/ +/, " ");

        dispatch( loadCourses(filteredTerm, searchType) );
        dispatch({ type : 'LOADING', payload: true });
    }

    /**
     * Values have been changed in the text input.
     */
    const handleSearchChange = (e) => {

        // If empty run set courses that we have already loaded.
        if ( null === e.target.value || '' === e.target.value ) {
            setTerm(e.target.value);
            dispatch({ type : 'EMPTY_SEARCH' })
            handleTypeChange( '', true)
        } else {
            setTerm(e.target.value);
        }
    }

    /**
     * If the select is changed re-run the queries.
     */
    const handleTypeChange = (e = false, the_term = false ) => {

        const type = e ? e.target.value : searchType;
        const activeTerm = ! the_term ? term : '';

        if ( e ) {
            dispatch({ type : 'SEARCH_TYPE', payload : {type: e.target.value} });
        }

        let loaded = false;

        console.log( activeTerm )

        /**
         * If term empty then check if we have grabbed the type archive before,
         * else check if we have searched this type before.
         */
        if ( '' === activeTerm ) {
            loaded = previouslyLoaded( type );
        } else {
            // check if searched.
            loaded = previouslySearched( type );
        }

        if ( loaded ) {

            dispatch({ type : 'LOADING', payload: true });

            setTimeout(() => {
                dispatch({ type : 'LOADING', payload: false });
            }, 1500);
            return;
        }

        dispatch( loadCourses(activeTerm, type) );
        dispatch({ type : 'LOADING', payload: true });
    }

    /**
     * Take the value & checks if we have loadaed data for it.
     */
    const previouslyLoaded = (val) => {
        let previouslyLoaded = false;

        const states = getAlphabetStates( val );

        if ( course[states['cpt']].length > 0  ) {
            previouslyLoaded = true;
        }

        return previouslyLoaded;
    }

    /**
     * Check if we have search for this term, in this type before.
     */
    const previouslySearched = (type) => {
        let previous = false;

        // Get the store keys related to this type.
        const states = getAlphabetStates( type );

        // Have we ever searched.
        if ( Object.keys(course[states['search']]).length > 0 ) {

            // Have we specifically searched this term.
            if ( course[states['search']][term] ) {
                previous = course[states['search']][term];
            }
        }

        /**
         * If previously searched set the previously
         * searched data as the active loop.
         */
        if ( previous ) {
            dispatch({
                type : 'PREVIOUS_SEARCH',
                payload : {
                    term: term,
                    type: searchType,
                    result: previous
                },
            })
        }
        return previous;
    }

    return (
        <header className="heading">
            <h2 className="is-3">Explore</h2>
            <p>Below you can find all of our content to help you decide where you would like to study.</p>

            <form onSubmit={submitCourseSearch} autoComplete="off" className="search">

                <fieldset>

                    <input
                    required
                    minLength="2"
                    type="search"
                    value={term}
                    placeholder="Search..."
                    disabled={isLoading}
                    onChange={handleSearchChange} />

                    <button type="submit" disabled={isLoading} className="search-icon cursor">
                        <img src={searchIcon} alt="Search icon" title="Submit search term" />
                    </button>
                </fieldset>

                <fieldset>

                    <select
                    required
                    onChange={handleTypeChange}
                    disabled={isLoading}
                    value={searchType}>
                        <option value="courses">Courses</option>
                        <option value="accommodation">Accommodation</option>
                        <option value="discover_glos">Discover Gloucestershire</option>
                        <option value="career">Your journey</option>
                        <option value="protecting">Our home</option>
                        <option value="safety">Our community</option>
                        <option value="fitting_in">Your safety</option>
                        <option value="clubs">Your future</option>
                        <option value="support">The planet</option>
                        <option value="fun_facts">Fun facts</option>
                        <option value="international_students">International students</option>
                    </select>
                </fieldset>
            </form>
        </header>
    )
});

export default Header;